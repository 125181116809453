import { withWidth } from "@material-ui/core";
import { styles as useStyles } from "./styles";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Archna from "../../../assets/images/archna.jpg";
import Rupesh from "../../../assets/images/rupesh.jpg";
import More from "../../../assets/images/more.svg";
import MoreHover from "../../../assets/images/moreHover.svg";

import LOGO1 from "../../../assets/images/home_award-01.png";
import LOGO2 from "../../../assets/images/home_award-02.png";
import LOGO3 from "../../../assets/images/home_award-03.png";
import LOGO4 from "../../../assets/images/home_award-04.png";



const HomeContent = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>


      <Container>
        <Row className="justify-content-center my-5">
          <Col md={9}>
            <Row className="justify-content-center my-0">
              <Col className="px-md-4" xs={3} md={3}><img src={LOGO1} alt="" /></Col>
              <Col className="px-md-4" xs={3} md={3}><img src={LOGO2} alt="" /></Col>
              <Col className="px-md-4" xs={3} md={3}><img src={LOGO3} alt="" /></Col>
              <Col className="px-md-4" xs={3} md={3}><img src={LOGO4} alt="" /></Col>
            </Row>
          </Col>
        </Row>

        <div className="d-md-none text-center mt-5 pt-3 mb-3">
          <h1 className={classes.h1}>
            globally eulogized interior & architectural firm</h1>
        </div>
        <Row className="justify-content-md-center">

          <Col md={9}>
            <Row className="justify-content-md-center">
              <Col xs={6} className="pe-0 pe-md-3">
                <div className={classes.HomeContent}>
                  <div className={classes.HomeContentImg}>
                    <img src={Archna} alt="" />
                  </div>
                  <div className={classes.HomeContents}>
                    <h6 className={classes.h6}> <strong>archana baid</strong>  </h6>
                  </div>
                </div>
              </Col>
              <Col xs={6} className="ps-0 ps-md-3">
                <div className={classes.HomeContent}>
                  <div className={classes.HomeContentImg}>
                    <img src={Rupesh} alt="" />
                  </div>
                  <div className={classes.HomeContents}>
                    <h6 className={classes.h6}> <strong>rupesh baid</strong>  </h6>

                  </div>
                </div>
              </Col>

            </Row>




          </Col>

          <Col md={9} className="mt-4 mt-md-5 text-center">
            <div className="d-none d-md-block"><h1 className={classes.h1}>globally eulogized <br /> interior & architectural firm</h1> </div>

            <p className={classes.p}>Each design to us is a rhapsody of our kaleidoscopic style, where different art inspirations and aesthetics coalesce to create enriching spaces emanating delicate beauty, precision and a valorous character. Our experience of collaborating with global artists, designers and stellar associations with
              acclaimed brands like SBID has enriched us as a brand.</p>

            <a href="/about" className="CtaBtn mt-4">
              <img src={More} alt="" className="Btn" />
              <img src={MoreHover} alt="" className="BtnHover" />
            </a>
          </Col>

        </Row>
      </Container>



    </div>

  );
};
export default withWidth()(HomeContent);
