import React from 'react';
import Navbar from './components/Navbar/Navbar';
//import ScrollTop from './components/Navbar/ScrollTop';
//import { Toolbar, Fab } from '@material-ui/core';
//import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MainRouter from './router';
const MainApp = () => {
  return (
    <>
      <Navbar />
      {/* <Toolbar id="back-to-top-anchor" /> */}
      <MainRouter />
      {/* <ScrollTop>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop> */}
    </>
  );
};

export default MainApp;
