import React from 'react';
import Footer from '../../components/Footer/Footer';
import HomeVideo from "../../components/Home/HomeVideo/HomeVideo";
import HomeContent from "../../components/Home/HomeContent/HomeContent";
import HomeSlider from "../../components/Home/HomeSlider/HomeSlider";
import HomeSlide from "../../components/Home/HomeSlide/HomeSlide";
import HomeBottom from "../../components/Home/HomeBottom/HomeBottom";
import WhyChoose from "../../components/Home/WhyChoose/WhyChoose";
import { styles as useStyles } from './styles';

const Home = () => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <HomeVideo />
      <HomeContent />
      <HomeSlider />
      <HomeBottom />
      <HomeSlide />
      <WhyChoose />
      <Footer />
    </div>
  );
};

export default Home;
