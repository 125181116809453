import {
  withWidth
} from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { styles as useStyles } from "./styles";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//import AboutYear from "../../../assets/images/about/Aboutyear.png";
import AboutYear from "../../../assets/video/our tale.mov";
import Watch from "../../../assets/images/contactBtn.svg";
import WatchHover from "../../../assets/images/contactBtn.svg";



const AboutContact = (props) => {
  const classes = useStyles();
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  return (

    <div className={classes.root}>


      <Container className={classes.mainMiddle}>

        <Row className="align-items-center justify-content-center">

          <Col lg={6} className="px-lg-4">
            <div className={classes.ConMobile}>

              <h1 className={classes.h1}>
                “Timeless bespoke designs since 2000.”</h1>

              <div className={classes.mY}></div>

              <p className={classes.p}>
                The aesthetic approach that And Design Co has today is an epitomised maturation of the inspirations and experiences the brand has gathered over 2 decades. 'AND' is a design philosophy enriched with historical knowledge of art and architecture synthesised with modern quirk.
              </p>
              <div className={classes.mY}></div>
              <div className="d-none d-lg-block">
                <a href="/contact" className="CtaBtn mt-4">
                  <img src={Watch} alt="" className="Btn" />
                  <img src={WatchHover} alt="" className="BtnHover" />
                </a>
              </div>



            </div>
          </Col>

          <Col xs={9} lg={4} className="px-lg-2 text-center mt-4 mt-lg-0">
            <video
              playsInline
              autoPlay
              loop
              muted
              src={AboutYear}
              ref={videoEl}
              className={classes.AboutYear}
            />

            <div className="d-lg-none">
              <a href="javascript:void(0);" className="CtaBtn mt-4">
                <img src={Watch} alt="" className="Btn" />
                <img src={WatchHover} alt="" className="BtnHover" />
              </a>
            </div>
          </Col>
        </Row>



      </Container>

    </div>

  );
};
export default withWidth()(AboutContact);
