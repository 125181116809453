import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DesktopVideo from "../../../assets/video/architecture.mp4";
import PlayButton from "../../../assets/images/PLAY.jpg";
import PauseButton from "../../../assets/images/pause.jpg";
class DetailVideo extends Component {
  constructor() {
    super();
    this.state = {
      videoPlay: true
    }
  }
  componentDidMount = () => {
    this.playVideo({ pre: true });
    this.refs.vidRef.pause();
  };

  componentWillUnmount = () => {
    this.pauseVideo();
  };

  playVideo = ({ pre }) => {
    // You can use the play method as normal on your video ref
    pre ? this.setState({ videoPlay: true }) : this.setState({ videoPlay: false });
    this.refs.vidRef.play();
  };

  pauseVideo = () => {
    // Pause as well
    this.setState({ videoPlay: true });
    this.refs.vidRef.pause();
  };

  render() {
    return (
      <div className="WhyChooseFull mt-5">
        <div className="VideoRelative">
          <Container>
            <Row className="justify-content-center">
              <Col md={10}>
                <div className="ratio ratio-16x9">
                  <video ref="vidRef" src={DesktopVideo} type="video/mp4" />
                  <div className="PlayPause playPauseTopLeft">
                    {this.state.videoPlay ?
                      <button onClick={this.playVideo}>
                        <img src={PlayButton} alt="Play" />
                      </button>
                      :
                      <button className="pause" onClick={this.pauseVideo}>
                        <img src={PauseButton} alt="Pause" />
                      </button>
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
export default DetailVideo;