import React from 'react';
import Footer from '../../components/Footer/Footer';
import AboutContent from '../../components/About/AboutContent/AboutContent';
import AboutImage from '../../components/About/AboutImage/AboutImage';
import AboutAD from '../../components/About/AboutAD/AboutAD';
import AboutContact from '../../components/About/AboutContact/AboutContact';
import Banner from "../../assets/images/about/banner.jpg";
import BannerMobile from "../../assets/images/about/bannerMobile.jpg";
import { styles as useStyles } from './styles';

const About = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <div className="AboutBanner">
        <img src={Banner} alt="" className="w-100 d-none d-md-block" />
        <img src={BannerMobile} alt="" className="w-100 d-md-none" />
      </div>
      <AboutContent />
      <AboutAD />
      <AboutImage />
      <AboutContact />
      <div className="AbBottom mb-4 mb-md-0">
        {/* <img src={BannerBottom} alt="" className="w-100" /> */}
      </div>
      <Footer />
    </div >
  );
};

export default About;
