import { makeStyles } from '@material-ui/core/styles';
import Instagram from "../../assets/images/contactMob.png";

export const styles = makeStyles((theme) => ({
  main: {
    overflow: 'hidden',
  },

  h1: {
    fontSize: '38px',
    color: '#8c7958',
    fontFamily: 'Cormorant Infant',
    lineHeight: '0.8',
    fontWeight: '500',
    textTransform: 'uppercase',
    [theme.breakpoints.down("md")]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "35px",
      textAlign: 'center',
      color: '#000000',
      paddingLeft: '30px',
      paddingRight: '30px',
    },
  },

  h2: {
    fontSize: '45px',
    color: '#fff',
    fontFamily: 'Cormorant Infant',
    lineHeight: '0.8',
    fontWeight: '300',
    letterSpacing: '6px',
    textTransform: 'uppercase',
    [theme.breakpoints.down("md")]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "21px",
      textAlign: 'center',
      color: '#ffffff',
      paddingLeft: '30px',
      paddingRight: '30px',
    },
  },

  H6: {
    textTransform: 'uppercase',
    fontSize: '19px',
    letterSpacing: '1px',
    marginBottom: '0',
    [theme.breakpoints.down("xs")]: {
      textAlign: 'center',
      color: '#000000',
      fontSize: '14px',
    },
  },
  Mail: {
    fontFamily: 'Cormorant Infant',
    fontSize: '14px',
    lineHeight: '1.2',
    fontWeight: 'normal',
    [theme.breakpoints.down("xs")]: {
      textAlign: 'center',
      color: '#000000',
      fontSize: '13px',
    },
  },
  p: {
    textTransform: 'uppercase',
    fontSize: '13px',
    letterSpacing: '1px',
    lineHeight: '1.2',
    marginBottom: '0',
    [theme.breakpoints.down("xs")]: {
      textAlign: 'center',
      color: '#000000',
      fontSize: '12px',
    },
  },
  Link: {
    textDecoration: 'underline',
    color: "#000000",
    [theme.breakpoints.down("xs")]: {
      color: "#000000",
    }
  },
  anch: {
    color: "#000000",
    textDecoration: 'none',
    [theme.breakpoints.down("xs")]: {
      color: "#000000",
    },

    "&:hover": {
      color: "#8c7958",
    },
  },
  mY: {
    marginTop: '3rem',
    marginBottom: '3rem',
    [theme.breakpoints.down("xs")]: {
      marginTop: '5rem',
      marginBottom: '5rem',
    }
  },

  Virmob: {
    margin: 'auto',
    display: 'block',
    width: '100px',
    marginBottom: '40px',
    [theme.breakpoints.up("sm")]: {
      display: 'none',
    },
  },
  ConMobile: {
    [theme.breakpoints.down("xs")]: {
      borderTopLeftRadius: '500px',
      borderTopRightRadius: '500px',
      padding: "5px 15px 0",

      paddingBottom: '30px',
      marginTop: '30px',
      position: 'relative',
      zIndex: '1',


      "&:before": {
        // backgroundImage: `url(${Instagram})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        //  backgroundColor: '#000',
        content: '""',
        borderTopLeftRadius: '500px',
        borderTopRightRadius: '500px',
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        margin: 'auto',
        zIndex: '-1',
      },

    },
  },

  ConX: {
    fontFamily: "Cormorant Infant",
    color: "#fff",
    lineHeight: "0.9",
    letterSpacing: "18px",
    textTransform: "uppercase",
    fontSize: "13px",
    textAlign: 'center',
    marginTop: '35px',
    [theme.breakpoints.up("sm")]: {
      display: 'none',
    },
  },

  mob: {
    margin: 'auto',
    display: 'block',
    [theme.breakpoints.up("sm")]: {
      display: 'none',
    },
  },
  desk: {
    [theme.breakpoints.down("xs")]: {
      display: 'none',
    },
  },
}));
