import { makeStyles } from "@material-ui/core/styles";
//import { Autocomplete } from "@material-ui/lab";

export const styles = makeStyles((theme) => ({


    root: {
        marginTop: '85px',
        marginBottom: '35px',

        [theme.breakpoints.down("xs")]: {
            marginTop: '45px',
        },
    },
    h1: {
        fontSize: "40px",
        fontFamily: 'Cormorant Infant',
        lineHeight: '0.8',
        [theme.breakpoints.down("md")]: {
            fontSize: "35px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "30px",
        },
    },
    h6: {
        fontSize: '16px',
        textTransform: 'uppercase',
        fontFamily: 'Cormorant Infant',
        lineHeight: '1',
        fontWeight: '600',
        marginBottom: '15px',
        [theme.breakpoints.down("xs")]: {
            fontSize: '14px',
        },
    },

    p: {
        fontSize: "16px",
        fontFamily: 'Cormorant Infant',
        lineHeight: '1',
        fontWeight: "500",
        marginTop: '0px'
    },
    archVideo: {
        width: '100%',
        height: 'auto',
    },

    p: {
        fontSize: "17px",
        fontFamily: 'Cormorant Infant',
        lineHeight: '1.3',
        fontWeight: "500",
        marginTop: '0px'
    },
    Img: {
        width: '120px',
        marginLeft: '15px',
        display: 'block',
        [theme.breakpoints.down("md")]: {
            width: '100px',
        },
        [theme.breakpoints.down("xs")]: {
            width: '80px',
        },
    },


}));
