import {
  withWidth
} from "@material-ui/core";
import { styles as useStyles } from "./styles";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import AboutOne from "../../../assets/images/founder.png";



const AboutContent = (props) => {
  const classes = useStyles();

  return (

    <div className={classes.root}>


      <Container className={classes.mainMiddle}>

        <Row className="align-items-center justify-content-center">
          <Col lg={11}>

            <Row className="align-items-center">
              <Col lg={4} className="text-center">
                <div className="d-lg-none text-center mb-4 mb-lg-0">
                  <h1 className={classes.h1}>
                    “Intellectualised designs that transcend time and exude classic luxury”</h1>
                </div>
                <img src={AboutOne} alt="" className={classes.AboutOne} />

              </Col>
              <Col lg={8} >
                <div className={classes.ConMobile}>
                  <div className="d-none d-lg-block">
                    <h1 className={classes.h1}>
                      “Intellectualised designs that transcend time and exude classic luxury”</h1>
                  </div>

                  <div className={classes.mY}></div>

                  <p className={classes.p}>
                    AND Design Co is an ambidextrous interior design and architecture studio, based in Mumbai and London. Our principal interior architects, Rupesh and Archana Baid are a perfect coalescence of intuitiveness and imagination. They are driven by an undaunted passion to create grandeur spaces that transcend time and meld historical art movements. As inquisitive designers, they both look forward to collaborating and sharing design experiences with artists from across the globe.
                  </p>
                  <p className={classes.p}>
                    Heterogeneous in character, precise in details, and bespoke in its essence, each project designed by AND is an intellectualised alchemy of tradition and modernization. We are an artist collective immersed in curating spaces that express the client’s brief through a harmonious amalgam of colours, textures, compositions, and materials. With this vision of understanding the subjectivity of luxury, we curate designs that reflect individuality and eclecticism in each of our projects.
                  </p>
                </div>
              </Col>
            </Row>

          </Col>
        </Row>



      </Container>

    </div>

  );
};
export default withWidth()(AboutContent);
