import {
  withWidth
} from "@material-ui/core";
import { styles as useStyles } from "./styles";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Watch from "../../../assets/images/contactBtn.svg";
import WatchHover from "../../../assets/images/contactBtn.svg";



const PressContact = (props) => {
  const classes = useStyles();



  return (

    <div className={classes.root}>


      <Container>
        <Row className=" justify-content-center">
          <Col lg={11}>
            <Row className="justify-content-center text-center text-lg-start">
              <Col lg={9}>
                <h2 className={classes.h1}>collaborations with the finest</h2>
                <p className={classes.p}>IF YOU WOULD LIKE TO DISCUSS YOUR PROJECT or collaboration OR SCHEDULE AN APPOINTMENT WITH OUR TEAM, EITHER IN PERSON OR VIRTUALLY, PLEASE get in touch with us.</p>
              </Col>
              <Col lg={3}>
                <a href="/contact" className="CtaBtn mt-4 mt-lg-0">
                  <img src={Watch} alt="" className="Btn" />
                  <img src={WatchHover} alt="" className="BtnHover" />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>





      </Container>

    </div>

  );
};
export default withWidth()(PressContact);
