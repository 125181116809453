import { makeStyles } from "@material-ui/core/styles";
//import { Autocomplete } from "@material-ui/lab";

export const styles = makeStyles((theme) => ({


  relative: {
    position: "relative",
    //height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  imgobject: {
    height: "100%",
    objectFit: "cover",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      height: "380px",
    },
  },

  HomeImg: {
    position: "absolute",
    right: "40px",
    bottom: "-100px",
    width: "180px",
    [theme.breakpoints.down("sm")]: {
      width: "150px",
      bottom: "-80px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "120px",
      bottom: "-60px",
      left: "0",
      right: '0',
      margin: "auto",
    },
  }
}));
