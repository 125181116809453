import { makeStyles } from "@material-ui/core/styles";
//import { Autocomplete } from "@material-ui/lab";

export const styles = makeStyles((theme) => ({

  root: {
    backgroundColor: '#100606',
    padding: "30px 20px 10px",
    [theme.breakpoints.down("xs")]: {
      padding: "60px 0 40px",
    },
  },
  relative: {
    position: "relative",
    //height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  imgobject: {


    "& video": {
      height: "27vw",
      objectFit: "cover",
      width: "100%",
      cursor: 'pointer',

      [theme.breakpoints.down("xs")]: {
        height: "50vw",
      },
    },


  },


  h2: {
    fontSize: "45px",
    textTransform: "uppercase",
    color: "#fff",
    fontWeight: "400",
    fontFamily: "Cormorant Infant",
    [theme.breakpoints.down("xs")]: {
      fontSize: "30px",
    },
  },


  p: {
    color: "#fff",
    fontSize: "14px",
    lineHeight: "1",
    textTransform: "uppercase",
    marginTop: "15px",
    letterSpacing: "0.6px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },

    "& strong": {
      fontWeight: '600',
    },

  }


}));
