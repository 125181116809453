import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DesktopVideo from "../../../assets/video/architecture.mp4";
import PlayButton from "../../../assets/images/PLAY.jpg";
import PauseButton from "../../../assets/images/pause.jpg";
class DetailVideo extends Component {
  constructor() {
    super();
    this.state = {
      videoPlay: true,
    }
    this.vidRef1 = React.createRef();
  }

  componentDidMount = () => {
    this.playVideo({ pre: true });
    this.vidRef1?.current?.pause();
  };

  componentWillUnmount = () => {
    this.pauseVideo();
  };

  playVideo = ({ pre }) => {
    pre ? this.setState({ videoPlay: true }) : this.setState({ videoPlay: false });
    this.vidRef1?.current?.play();
  };

  pauseVideo = () => {
    this.setState({ videoPlay: true });
    this.vidRef1?.current?.pause();
  };

  render() {
    return (
      <div className="WhyChooseFull mt-5">
        <div className="VideoRelative">
          <Container>
            <Row className="justify-content-center">
              <Col md={10}>
                {this.props.video !== "" &&
                  <div className="ratio ratio-16x9">
                    <video ref={this.vidRef1} src={this.props.video} type="video/mp4" />
                    <div className="PlayPause playPauseTopLeft">
                      {this.state.videoPlay ?
                        <button onClick={this.playVideo}>
                          <img src={PlayButton} alt="Play" />
                        </button>
                        :
                        <button className="pause" onClick={this.pauseVideo}>
                          <img src={PauseButton} alt="Pause" />
                        </button>
                      }
                    </div>
                  </div>
                }
                <div className="boxArrow">
                  <a className="boxLink" href={`/interior-detail/${this.props.id === 1 ? 1 : this.props.id - 1}`}>
                    <svg viewBox="0 0 214.42 214.42"><title>arrow left</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="clsOne" d="M87.74,98.63v6.92h50.52v2.69H87.76v7.49l-.1.06c-5.18-4.07-6.87-5.36-11.12-8.88C80.14,104.3,84.09,101.27,87.74,98.63Z" /><circle className="clsTwo" cx="107.21" cy="107.21" r="106.5" /></g></g></svg>
                    PREVIOUS PROJECT
                  </a>
                  <a className="boxLink" href={`/interior-detail/${this.props.id === this.props.total ? 1 : this.props.id + 1}`} >
                    NEXT PROJECT
                    <svg viewBox="0 0 214.42 214.42"><title>arrow right</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="clsOne" d="M127.07,98.63v6.92H76.54v2.69h50.51v7.49l.1.06c5.18-4.07,6.86-5.36,11.11-8.88C134.67,104.3,130.72,101.27,127.07,98.63Z" /><circle className="clsTwo" cx="107.21" cy="107.21" r="106.5" /></g></g></svg>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
export default DetailVideo;