import React, { Component } from "react";

// import Slider from "../../../assets/images/slide.jpg";
import Slider1 from "../../../assets/images/image-A.png";
import Slider2 from "../../../assets/images/image-B.png";
import Slider3 from "../../../assets/images/image-C.png";

// import SliderMobile from "../../../assets/images/slideMobile.jpg";

export default class MultipleRows extends Component {
  render() {

    return (
      <div className="mb-4">
        <div className="Homslider">
          <div className="slide-track">
            <div className="slide">
              <img src={Slider1} alt="" />
              {/* <img src={SliderMobile} alt="" className="d-md-none" /> */}
            </div>
            <div className="slide">
              <img src={Slider2} alt="" />
              {/* <img src={SliderMobile} alt="" className="d-md-none" /> */}
            </div>
            <div className="slide">
              <img src={Slider3} alt="" />
              {/* <img src={SliderMobile} alt="" className="d-md-none" /> */}
            </div>
            <div className="slide">
              <img src={Slider1} alt="" />
              {/* <img src={SliderMobile} alt="" className="d-md-none" /> */}
            </div>

          </div>
        </div>
      </div>
    );
  }
}