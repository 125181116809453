import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import Slider1 from "../../../assets/images/architecture/banner/IMAGE1.jpg";
import Slider2 from "../../../assets/images/architecture/banner/IMAGE2.jpg";
import Slider3 from "../../../assets/images/architecture/banner/IMAGE3.jpg";
import Slider4 from "../../../assets/images/architecture/banner/IMAGE4.jpg";
import Slider5 from "../../../assets/images/architecture/banner/IMAGE5.jpg";
import Slider6 from "../../../assets/images/architecture/banner/IMAGE6.jpg";
import Slider7 from "../../../assets/images/architecture/banner/IMAGE7.jpg";
import Slider8 from "../../../assets/images/architecture/banner/IMAGE8.jpg";
import Slider9 from "../../../assets/images/architecture/banner/IMAGE9.jpg";
import Slider10 from "../../../assets/images/architecture/banner/IMAGE10.jpg";
import Slider11 from "../../../assets/images/architecture/banner/IMAGE11.jpg";
import Slider12 from "../../../assets/images/architecture/banner/IMAGE12.jpg";

export default class CenterMode extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: true,
      infinite: true,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 800,
      pauseOnHover: false,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
      ],
    };

    return (
      <div className="ArrowXl DotsSlider">
        <Slider {...settings}>
          <div className="feat-img">
            <img src={Slider1} alt="" />
          </div>

          <div className="feat-img">
            <img src={Slider2} alt="" />
          </div>
          <div className="feat-img">
            <img src={Slider3} alt="" />
          </div>
          <div className="feat-img">
            <img src={Slider4} alt="" />
          </div>
          <div className="feat-img">
            <img src={Slider5} alt="" />
          </div>
          <div className="feat-img">
            <img src={Slider6} alt="" />
          </div>
          <div className="feat-img">
            <img src={Slider7} alt="" />
          </div>
          <div className="feat-img">
            <img src={Slider8} alt="" />
          </div>
          <div className="feat-img">
            <img src={Slider9} alt="" />
          </div>
          <div className="feat-img">
            <img src={Slider10} alt="" />
          </div>
          <div className="feat-img">
            <img src={Slider11} alt="" />
          </div>
          <div className="feat-img">
            <img src={Slider12} alt="" />
          </div>

        </Slider>
      </div>
    );
  }
}
