import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
//import Slider1 from "../../../assets/images/interior-detail/image1.jpg";
//import Slider2 from "../../../assets/images/interior-detail/image2.jpg";
//import Slider3 from "../../../assets/images/interior-detail/image3.jpg";
//import Slider4 from "../../../assets/images/interior-detail/image4.jpg";
//import Slider5 from "../../../assets/images/interior-detail/image5.jpg";
//import Slider6 from "../../../assets/images/interior-detail/image6.jpg";

export default class CenterMode extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: true,
      infinite: true,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 800,
      pauseOnHover: false,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
      ],
    };

    return (
      <div className="ArrowXl DotsSlider">
        <Slider {...settings}>
          {this.props.images?.map((img)=>{
            return <div className="feat-img">
            <img src={img.picture} alt={img.alt} />
          </div>
          })}
          
          {/* <div className="feat-img">
            <img src={Slider2} alt="" />
          </div>
          <div className="feat-img">
            <img src={Slider3} alt="" />
          </div>
          <div className="feat-img">
            <img src={Slider4} alt="" />
          </div>
          <div className="feat-img">
            <img src={Slider5} alt="" />
          </div>
          <div className="feat-img">
            <img src={Slider6} alt="" />
          </div> */}

        </Slider>
      </div>
    );
  }
}
