import { makeStyles } from "@material-ui/core/styles";
//import { Autocomplete } from "@material-ui/lab";

export const styles = makeStyles((theme) => ({


    root: {
        marginTop: '35px',
        marginBottom: '35px',
    },
    HomeContent: {
        margin: "0 5px",
        [theme.breakpoints.down("xs")]: {
            margin: "0 0.5px",
        },
    },

    HomeContentImg: {
        position: "relative",
        cursor: 'pointer',
        overflow: "hidden",

        "& img": {
            width: '100%',

            transition: "all ease 0.9s",

        },


        "&:after": {
            border: "0.8px solid #fff",
            content: '""',
            position: "absolute",
            right: "10px",
            top: "10px",
            left: "10px",
            bottom: "10px",
            borderRadius: "50%",
            opacity: "0",
            transition: "all ease 0.3s",
        },

        "&:hover": {

            "& img": {
                transform: "scale(1.1)",

            },

            "&:after": {
                opacity: "1",
            },

        },
    },

    h6: {
        fontSize: '16px',
        textTransform: 'uppercase',
        letterSpacing: '0.6px',
        marginBottom: '0',
        [theme.breakpoints.down("xs")]: {
            fontSize: '14px',
        },
    },
    textRight: {
        textAlign: 'right',
        paddingLeft: '8px',
        [theme.breakpoints.down("xs")]: {
            textAlign: 'left',
            paddingLeft: '0px',
            fontSize: '12px',
        },
    },
    HomeContents: {
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'center',


    },

    h1: {
        fontSize: "45px",
        fontFamily: 'Cormorant Infant',
        textTransform: 'uppercase',
        maxWidth: '870px',
        margin: 'auto',
        lineHeight: '1',
        [theme.breakpoints.down("xs")]: {
            fontSize: "30px",
        },
    },
    p: {
        fontSize: "16px",
        fontFamily: 'Cormorant Infant',
        lineHeight: '1.3',
        maxWidth: '840px',
        margin: 'auto',
        fontWeight: "500",
        marginTop: '10px',
        textAlign: 'justify',
        textAlignLast: 'center'
    }
}));
