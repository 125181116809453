import React, { useEffect } from "react";
import { withWidth } from "@material-ui/core";
import { styles as useStyles } from "./styles";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AD from "../../../assets/images/AD100.svg";
import Year from "../../../assets/images/21years.svg";
import VITRUVIAN from "../../../assets/images/VITRUVIAN-MAN.png";

import VITRUVIUS from "../../../assets/images/VITRUVIUS.png";
import WOW from "wowjs";


const HomeBottom = (props) => {
  const classes = useStyles();
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();

  }, []);
  return (
    <div className={classes.root}>
      <Container>
        <Row className="justify-content-center">
          <Col lg={10}>
            <Row className="justify-content-center align-items-center">

              <Col xs={4} md={3} className="order-4 order-md-2 wow fadeInDown">
                <img src={Year} alt="" className={classes.ImgYear} />
              </Col>
              <Col xs={12} md={3} className="d-none d-md-block order-3 order-md-3 wow fadeInDown">
                <img src={VITRUVIAN} alt="" className={classes.ImgVitr} />
              </Col>

              <Col xs={4} md={3} className="order-1 order-md-3 wow fadeInDown">
                <div>
                  <h2 className={classes.h1}>
                    “Firmitas,
                    utilitas,
                    venustas.” </h2>
                  <p className={classes.p2}>― Vitruvius</p>
                </div>
              </Col>



              <Col xs={4} md={3} className="order-2 order-md-4 wow fadeInDown">
                <div className="d-flex align-items-center">

                  <img src={VITRUVIUS} alt="" className={classes.Img} />
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={10} className="mt-3 mt-md-5 text-center">
            <p className={classes.p}>Our affection towards Vitruvius is almost fanatical. The magisterial principles of strength, utility, and beauty of the great Roman architect and engineer are the inception layers of And Design Co. Propelled by imagination, creativity, and expression, we seek inspiration from its form of the classical era, emotions of romanticism and the charms of tradition. We like to call ourselves motley in our style of design.  </p>
          </Col>
        </Row>
      </Container>
    </div>

  );
};
export default withWidth()(HomeBottom);
