import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  main: {
    overflow: 'hidden',
    paddingTop: '30px',
    [theme.breakpoints.down("xs")]: {
      paddingTop: '10px',
    },
  },

  h1: {
    fontSize: '40px',
    color: '#060606',
    fontFamily: 'Cormorant Infant',
    lineHeight: '0.8',
    fontWeight: '500',
    textTransform: 'uppercase',
    [theme.breakpoints.down("md")]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "32px",
      textAlign: 'center',
    },
  },

  H6: {
    fontSize: '16px',
    marginBottom: '0',
    letterSpacing: '0.6px',
    marginTop: '5px',
    fontWeight: 'bold',
    lineHeight: 'normal',
    [theme.breakpoints.down("xs")]: {
      fontSize: '14px',
    },
  },
  p: {
    fontWeight: '400',
    fontSize: '16px',
    color: '#99896c',
    lineHeight: 'Normal',
    letterSpacing: '0.6px',
    marginBottom: 0,
    textTransform: 'uppercase',
  },
  Margin: {
    marginTop: '25px',
    [theme.breakpoints.down("xs")]: {
      marginTop: '20px',
    },
  },




}));
