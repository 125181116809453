//correct data
import Watch from "../assets/images/takeButton.jpg";
import WatchHover from "../assets/images/takeButtonHover.jpg";
import Interiors1 from "../assets/images/interiors/interiors-1.jpg";
import Interiors2 from "../assets/images/interiors/interiors-2.jpg";
import Interiors3 from "../assets/images/interiors/interiors-3.jpg";
import Interiors4 from "../assets/images/interiors/circle_video.mov";
import Interiors5 from "../assets/images/interiors/interiors-5.jpg";
import Interiors6 from "../assets/images/interiors/interiors-6.jpg";
import Interiors7 from "../assets/images/interiors/interiors-7.jpg";
import Interiors8 from "../assets/images/interiors/interiors-8.jpg";
import Interiors9 from "../assets/images/interiors/interiors-9.jpg";
import Interiors10 from "../assets/images/interiors/interiors-10.jpg";
import Interiors11 from "../assets/images/interiors/interiors-11.jpg";
import Interiors12 from "../assets/images/interiors/interiors-12.jpg";
import Interiors13 from "../assets/images/interiors/interiors-13.jpg";
import Interiors14 from "../assets/images/interiors/interiors-14.jpg";

import Slider1 from "../assets/images/interior-detail/imageA.jpg";
import Slider2 from "../assets/images/interior-detail/imageB.jpg";
import Slider3 from "../assets/images/interior-detail/imageC.jpg";

import ProjectTwoSlider1 from "../assets/images/interior-detail/projects/project2/ImageA.png";
import ProjectTwoSlider2 from "../assets/images/interior-detail/projects/project2/ImageB.png";
import ProjectTwoSlider3 from "../assets/images/interior-detail/projects/project2/ImageC.png";

import ProjectThreeSlider1 from "../assets/images/interior-detail/projects/project3/ImageA.png";
import ProjectThreeSlider2 from "../assets/images/interior-detail/projects/project3/ImageB.png";
import ProjectThreeSlider3 from "../assets/images/interior-detail/projects/project3/ImageC.png";

import ProjectFourSlider1 from "../assets/images/interior-detail/projects/project4/ImageA.png";
import ProjectFourSlider2 from "../assets/images/interior-detail/projects/project4/ImageB.png";
import ProjectFourSlider3 from "../assets/images/interior-detail/projects/project4/ImageC.png";

import ProjectFiveSlider1 from "../assets/images/interior-detail/projects/project5/ImageA.png";
import ProjectFiveSlider2 from "../assets/images/interior-detail/projects/project5/ImageB.png";
import ProjectFiveSlider3 from "../assets/images/interior-detail/projects/project5/ImageC.png";

import ProjectSixSlider1 from "../assets/images/interior-detail/projects/project6/ImageA.png";
import ProjectSixSlider2 from "../assets/images/interior-detail/projects/project6/ImageB.png";
import ProjectSixSlider3 from "../assets/images/interior-detail/projects/project6/ImageC.png";

import ProjectSevenSlider1 from "../assets/images/interior-detail/projects/project7/ImageA.png";
import ProjectSevenSlider2 from "../assets/images/interior-detail/projects/project7/ImageB.png";
import ProjectSevenSlider3 from "../assets/images/interior-detail/projects/project7/ImageC.png";

import ProjectEightSlider1 from "../assets/images/interior-detail/projects/project8/ImageA.png";
import ProjectEightSlider2 from "../assets/images/interior-detail/projects/project8/ImageB.png";
import ProjectEightSlider3 from "../assets/images/interior-detail/projects/project8/ImageC.png";

import ProjectNineSlider1 from "../assets/images/interior-detail/projects/project9/ImageA.png";
import ProjectNineSlider2 from "../assets/images/interior-detail/projects/project9/ImageB.png";
import ProjectNineSlider3 from "../assets/images/interior-detail/projects/project9/ImageC.png";

import ProjectTenSlider1 from "../assets/images/interior-detail/projects/project10/ImageA.png";
import ProjectTenSlider2 from "../assets/images/interior-detail/projects/project10/ImageB.png";
import ProjectTenSlider3 from "../assets/images/interior-detail/projects/project10/ImageC.png";
import ProjectTenSlider4 from "../assets/images/interior-detail/projects/project10/ImageD.png";

import ProjectElevenSlider1 from "../assets/images/interior-detail/projects/project11/ImageA.png";
import ProjectElevenSlider2 from "../assets/images/interior-detail/projects/project11/ImageB.png";
import ProjectElevenSlider3 from "../assets/images/interior-detail/projects/project11/ImageC.png";

import ProjectTwelveSlider1 from "../assets/images/interior-detail/projects/project12/ImageA.png";
import ProjectTwelveSlider2 from "../assets/images/interior-detail/projects/project12/ImageB.png";
import ProjectTwelveSlider3 from "../assets/images/interior-detail/projects/project12/ImageC.png";

import ProjectThirtySlider1 from "../assets/images/interior-detail/projects/project13/ImageA.png";
import ProjectThirtySlider2 from "../assets/images/interior-detail/projects/project13/ImageB.png";
import ProjectThirtySlider3 from "../assets/images/interior-detail/projects/project13/ImageC.png";



import MainSlider1 from "../assets/images/interior-detail/image1.jpg";
import MainSlider2 from "../assets/images/interior-detail/image2.jpg";
import MainSlider3 from "../assets/images/interior-detail/image3.jpg";
import MainSlider4 from "../assets/images/interior-detail/image4.jpg";
import MainSlider5 from "../assets/images/interior-detail/image5.jpg";
import MainSlider6 from "../assets/images/interior-detail/image6.jpg";

import ProjectTwo1 from "../assets/images/interior-detail/projects/project2/image1.png";
import ProjectTwo2 from "../assets/images/interior-detail/projects/project2/image2.png";
import ProjectTwo3 from "../assets/images/interior-detail/projects/project2/image3.png";
import ProjectTwo4 from "../assets/images/interior-detail/projects/project2/image4.png";
import ProjectTwo5 from "../assets/images/interior-detail/projects/project2/image5.png";
import ProjectTwo6 from "../assets/images/interior-detail/projects/project2/image6.png";

import ProjectThree1 from "../assets/images/interior-detail/projects/project3/image1.png";
import ProjectThree2 from "../assets/images/interior-detail/projects/project3/image2.png";
import ProjectThree3 from "../assets/images/interior-detail/projects/project3/image3.png";
import ProjectThree4 from "../assets/images/interior-detail/projects/project3/image4.png";
import ProjectThree5 from "../assets/images/interior-detail/projects/project3/image5.png";

import ProjectFour1 from "../assets/images/interior-detail/projects/project4/image1.png";
import ProjectFour2 from "../assets/images/interior-detail/projects/project4/image2.png";
import ProjectFour3 from "../assets/images/interior-detail/projects/project4/image3.png";
import ProjectFour4 from "../assets/images/interior-detail/projects/project4/image4.png";
import ProjectFour5 from "../assets/images/interior-detail/projects/project4/image5.png";
import ProjectFour6 from "../assets/images/interior-detail/projects/project4/image6.png";

import ProjectFive1 from "../assets/images/interior-detail/projects/project5/image1.png";
import ProjectFive2 from "../assets/images/interior-detail/projects/project5/image2.png";
import ProjectFive3 from "../assets/images/interior-detail/projects/project5/image3.png";
import ProjectFive4 from "../assets/images/interior-detail/projects/project5/image4.png";
import ProjectFive5 from "../assets/images/interior-detail/projects/project5/image5.png";
import ProjectFive6 from "../assets/images/interior-detail/projects/project5/image6.png";

import ProjectSix1 from "../assets/images/interior-detail/projects/project6/image1.png";
import ProjectSix2 from "../assets/images/interior-detail/projects/project6/image2.png";
import ProjectSix3 from "../assets/images/interior-detail/projects/project6/image3.png";
import ProjectSix4 from "../assets/images/interior-detail/projects/project6/image4.png";
import ProjectSix5 from "../assets/images/interior-detail/projects/project6/image5.png";
import ProjectSix6 from "../assets/images/interior-detail/projects/project6/image6.png";

import ProjectSeven1 from "../assets/images/interior-detail/projects/project7/image1.png";
import ProjectSeven2 from "../assets/images/interior-detail/projects/project7/image2.png";
import ProjectSeven3 from "../assets/images/interior-detail/projects/project7/image3.png";
import ProjectSeven4 from "../assets/images/interior-detail/projects/project7/image4.png";
import ProjectSeven5 from "../assets/images/interior-detail/projects/project7/image5.png";
import ProjectSeven6 from "../assets/images/interior-detail/projects/project7/image6.png";

import ProjectEight1 from "../assets/images/interior-detail/projects/project8/image1.png";
import ProjectEight2 from "../assets/images/interior-detail/projects/project8/image2.png";
import ProjectEight3 from "../assets/images/interior-detail/projects/project8/image3.png";
import ProjectEight4 from "../assets/images/interior-detail/projects/project8/image4.png";
import ProjectEight5 from "../assets/images/interior-detail/projects/project8/image5.png";
import ProjectEight6 from "../assets/images/interior-detail/projects/project8/image6.png";

import ProjectNine1 from "../assets/images/interior-detail/projects/project9/image1.png";
import ProjectNine2 from "../assets/images/interior-detail/projects/project9/image2.png";
import ProjectNine3 from "../assets/images/interior-detail/projects/project9/image3.png";
import ProjectNine4 from "../assets/images/interior-detail/projects/project9/image4.png";
import ProjectNine5 from "../assets/images/interior-detail/projects/project9/image5.png";
import ProjectNine6 from "../assets/images/interior-detail/projects/project9/image6.png";

import ProjectTen1 from "../assets/images/interior-detail/projects/project10/image1.png";
import ProjectTen2 from "../assets/images/interior-detail/projects/project10/image2.png";
import ProjectTen3 from "../assets/images/interior-detail/projects/project10/image3.png";
import ProjectTen4 from "../assets/images/interior-detail/projects/project10/image4.png";
import ProjectTen5 from "../assets/images/interior-detail/projects/project10/image5.png";
import ProjectTen6 from "../assets/images/interior-detail/projects/project10/image6.png";

import ProjectEleven1 from "../assets/images/interior-detail/projects/project11/image1.png";
import ProjectEleven2 from "../assets/images/interior-detail/projects/project11/image2.png";
import ProjectEleven3 from "../assets/images/interior-detail/projects/project11/image3.png";

import ProjectTwelve1 from "../assets/images/interior-detail/projects/project12/image1.png";
import ProjectTwelve2 from "../assets/images/interior-detail/projects/project12/image2.png";
import ProjectTwelve3 from "../assets/images/interior-detail/projects/project12/image3.png";

import ProjectThirty1 from "../assets/images/interior-detail/projects/project13/image1.png";
import ProjectThirty2 from "../assets/images/interior-detail/projects/project13/image2.png";
import ProjectThirty3 from "../assets/images/interior-detail/projects/project13/image3.png";

import DetailOne from "../assets/images/interior-detail/circle/project1.png";
import DetailTwo from "../assets/images/interior-detail/circle/project2.png";
import DetailThree from "../assets/images/interior-detail/circle/project4.png";
import DetailFour from "../assets/images/interior-detail/circle/project5.png";
import DetailFive from "../assets/images/interior-detail/circle/project6.png";
import DetailSix from "../assets/images/interior-detail/circle/project7.png";
import DetailSeven from "../assets/images/interior-detail/circle/project8.png";
import DetailEight from "../assets/images/interior-detail/circle/project9.png";
import DetailNine from "../assets/images/interior-detail/circle/project10.png";
import DetailTen from "../assets/images/interior-detail/circle/project3.png";
import DetailThirteen from "../assets/images/interior-detail/circle/project13.png";

import DetailSecond from "../assets/images/interior-detail/section2.png";
import ContentVideo1 from "../assets/video/PROJECT1_VIDEO.mp4";
import ContentVideo2 from "../assets/video/PROJECT2_VIDEO.mp4";
import ContentVideo5 from "../assets/video/PROJECT4_VIDEO.mp4";
import ContentVideo6 from "../assets/video/PROJECT5_VIDEO.mp4";
import ContentVideo7 from "../assets/video/PROJECT6_VIDEO.mp4";
import ContentVideo8 from "../assets/video/PROJECT7_VIDEO.mp4";
import ContentVideo11 from "../assets/video/PROJECT10.mp4";
import ContentVideo13 from "../assets/video/PROJECT13_VIDEO.mp4";


import DetailSecondMobile from "../assets/images/interior-detail/sonshinevilla.png";
//import ProjectTwoDetailOne from "../assets/images/interior-detail/projects/project2/image-1.png";
import ProjectTwoDetailSecondMobile from "../assets/images/interior-detail/projects/project2/bullofheaven.png";

//import ProjectThreeDetailOne from "../assets/images/interior-detail/projects/project3/image-1.png";
import ProjectThreeDetailSecondMobile from "../assets/images/interior-detail/projects/project3/victorianvilla.png";

//import ProjectFourDetailOne from "../assets/images/interior-detail/projects/project4/image-1.png";
import ProjectFourDetailSecondMobile from "../assets/images/interior-detail/projects/project4/section2.png";

//import ProjectFiveDetailOne from "../assets/images/interior-detail/projects/project5/image-1.png";
import ProjectFiveDetailSecondMobile from "../assets/images/interior-detail/projects/project5/plumeofparadise.png";

//import ProjectSixDetailOne from "../assets/images/interior-detail/projects/project6/image-1.png";
import ProjectSixDetailSecondMobile from "../assets/images/interior-detail/projects/project6/section2.png";

//import ProjectSevenDetailOne from "../assets/images/interior-detail/projects/project7/image-1.png";
import ProjectSevenDetailSecondMobile from "../assets/images/interior-detail/projects/project7/eliteshowroom.png";

//import ProjectEightDetailOne from "../assets/images/interior-detail/projects/project8/image-1.png";
import ProjectEightDetailSecondMobile from "../assets/images/interior-detail/projects/project8/chapters.png";

//import ProjectNineDetailOne from "../assets/images/interior-detail/projects/project9/image-1.png";
import ProjectNineDetailSecondMobile from "../assets/images/interior-detail/projects/project9/rusticreflections.png";

//import ProjectTenDetailOne from "../assets/images/interior-detail/projects/project10/image-1.png";
import ProjectTenDetailSecondMobile from "../assets/images/interior-detail/projects/project10/section2.png";

//import ProjectElevenDetailOne from "../assets/images/interior-detail/projects/project11/image-1.png";
import ProjectElevenDetailSecondMobile from "../assets/images/interior-detail/projects/project11/section2.png";
import ProjectTwelveDetailSecondMobile from "../assets/images/interior-detail/projects/project12/section2.png";
import ProjectThirtyDetailSecondMobile from "../assets/images/interior-detail/projects/project13/section2.png";


///others///
//import ProjectTwoDetailOne from "../assets/images/interior-detail/projects/project2/image-1.png";
import ProjectTwoDetailSecond from "../assets/images/interior-detail/projects/project2/section2.png";

//import ProjectThreeDetailOne from "../assets/images/interior-detail/projects/project3/image-1.png";
import ProjectThreeDetailSecond from "../assets/images/interior-detail/projects/project3/section2.png";

//import ProjectFourDetailOne from "../assets/images/interior-detail/projects/project4/image-1.png";
import ProjectFourDetailSecond from "../assets/images/interior-detail/projects/project4/section2.png";

//import ProjectFiveDetailOne from "../assets/images/interior-detail/projects/project5/image-1.png";
import ProjectFiveDetailSecond from "../assets/images/interior-detail/projects/project5/section2.png";

//import ProjectSixDetailOne from "../assets/images/interior-detail/projects/project6/image-1.png";
import ProjectSixDetailSecond from "../assets/images/interior-detail/projects/project6/section2.png";

//import ProjectSevenDetailOne from "../assets/images/interior-detail/projects/project7/image-1.png";
import ProjectSevenDetailSecond from "../assets/images/interior-detail/projects/project7/section2.png";

//import ProjectEightDetailOne from "../assets/images/interior-detail/projects/project8/image-1.png";
import ProjectEightDetailSecond from "../assets/images/interior-detail/projects/project8/section2.png";

//import ProjectNineDetailOne from "../assets/images/interior-detail/projects/project9/image-1.png";
import ProjectNineDetailSecond from "../assets/images/interior-detail/projects/project9/section2.png";

//import ProjectTenDetailOne from "../assets/images/interior-detail/projects/project10/image-1.png";
import ProjectTenDetailSecond from "../assets/images/interior-detail/projects/project10/section2.png";

//import ProjectElevenDetailOne from "../assets/images/interior-detail/projects/project11/image-1.png";
import ProjectElevenDetailSecond from "../assets/images/interior-detail/projects/project11/section2.png";

import ProjectTwelveDetailSecond from "../assets/images/interior-detail/projects/project12/section2.png";
import ProjectThirtyDetailSecond from "../assets/images/interior-detail/projects/project13/section2.png";


export const watch = Watch;
export const watchHover = WatchHover;
export const interiors = [
  {
    id: 1, picture: Interiors1, url: "/interior-detail/1", text: "SONSHINE VILLA", isCircle: false, video: 0,
    detail: {
      id: 1,
      mainHeading: "SONSHINE VILLA",
      subStrongHeading: "",
      subHeading: "A CONTEMPORARY DESIGN",
      detailPicture1: DetailOne,
      detailPicture2: DetailSecond,
      detailPicture3: DetailSecondMobile,
      content: "ALCHEMY OF EFFORTLESS ELEGANCE & GRANDEUR",
      subContent: "Sonshine Villa emerges as a mesmerising kaleidoscope of design, where the demarcations between modernity and tradition wane, giving birth to an entrancing interior",
      subContent2: '"It is about weaving ideas from the modern day and elements from the traditional past into a contemporary form," a whisper that encapsulates the essence of this transformative endeavour.',
      subContent3: "Designing this multi-generational haven was a fully immersive experience, with the traditional forms serving as a daring canvas for an inventive experimentation. The residence came to life with the infusion of cultural relics from bygone eras, tenderly juxtaposed against the majestic allure of marble, infusing the space with a poignant narrative that echoes through its walls",
      subContent4: "AND Design, celebrated for transcending the boundaries of sophistication, transmuted this sanctuary into a cultural oasis. Modern and traditional elements danced in seamless harmony, crafting a captivating interplay of a dwelling that bore the marks of ancient relics, with treasures like Burmese medicine boxes, the Andhra beetle leaf box, and vintage South Indian rice spoons gracing its shelves, like echoes of time reverberating through the ages. The transition from soft, warm ambience to bold and powerful character unfolded with effortless grace, all while bathed in the caressing embrace of abundant daylight. A fusion of Indian crafts, rustic mosaics, and ethnically rooted artefacts wove a vibrant tapestry of nostalgia, summoning forth a sense of timelessness and cultural opulence that left an indelible mark on the soul of the residence.",

      video: ContentVideo1,
      middleSliderPictures: [
        { id: 1, picture: Slider1, alt: "slider-1", heading: "EACH PROJECT DESIGNED BY ‘AND’ IS AN INTELLECTUALISED ALCHEMY OF TRADITION AND MODERNIZATION." },
        { id: 2, picture: Slider2, alt: "slider-2", heading: "EACH PROJECT DESIGNED BY ‘AND’ IS AN INTELLECTUALISED ALCHEMY OF TRADITION AND MODERNIZATION." },
        { id: 3, picture: Slider3, alt: "slider-3", heading: "EACH PROJECT DESIGNED BY ‘AND’ IS AN INTELLECTUALISED ALCHEMY OF TRADITION AND MODERNIZATION." }
      ],
      mainSliderPictures: [
        { id: 1, picture: MainSlider1, alt: "slider-1", heading: "EACH PROJECT DESIGNED BY ‘AND’ IS AN INTELLECTUALISED ALCHEMY OF TRADITION AND MODERNIZATION." },
        { id: 2, picture: MainSlider2, alt: "slider-2", heading: "EACH PROJECT DESIGNED BY ‘AND’ IS AN INTELLECTUALISED ALCHEMY OF TRADITION AND MODERNIZATION." },
        { id: 3, picture: MainSlider3, alt: "slider-3", heading: "EACH PROJECT DESIGNED BY ‘AND’ IS AN INTELLECTUALISED ALCHEMY OF TRADITION AND MODERNIZATION." },
        { id: 4, picture: MainSlider4, alt: "slider-4", heading: "EACH PROJECT DESIGNED BY ‘AND’ IS AN INTELLECTUALISED ALCHEMY OF TRADITION AND MODERNIZATION." },
        { id: 5, picture: MainSlider5, alt: "slider-5", heading: "EACH PROJECT DESIGNED BY ‘AND’ IS AN INTELLECTUALISED ALCHEMY OF TRADITION AND MODERNIZATION." },
        { id: 6, picture: MainSlider6, alt: "slider-6", heading: "EACH PROJECT DESIGNED BY ‘AND’ IS AN INTELLECTUALISED ALCHEMY OF TRADITION AND MODERNIZATION." }
      ],
    }
  },
  {
    id: 2, picture: Interiors2, url: "/interior-detail/2", text: "THE BULL OF HEAVEN", isCircle: false, video: 0,
    detail: {
      id: 2,
      mainHeading: "THE BULL OF HEAVEN",
      subStrongHeading: "",
      subHeading: "A CONTEMPORARY DESIGN",
      detailPicture1: DetailTwo,
      detailPicture2: ProjectTwoDetailSecond,
      detailPicture3: ProjectTwoDetailSecondMobile,
      content: "AESTHETIC ANTIQUITY OF GRECO-ROMAN ENVIRONS",
      subContent: "In the realm of Greco-Roman antiquity, ‘The Bull of Heaven” bears its genesis in the hallowed annals of Greek mythology, where a resplendent bull takes centre stage within the intricate tapestry of our interior design. This majestic creature symbolises the profound principles of rebirth and the divine descent of consciousness, as narrated in the sagas of old. As we delve into the artistry of this creation, the lavish marble adornments, the carefully integrated Roman red hues in the minutest details, and the gilded embellishments harmonise in unison to splendidly enhance the prevailing Greco-Roman ambience within these walls.",
      subContent2: "",

      video: ContentVideo2,
      middleSliderPictures: [
        { id: 1, picture: ProjectTwoSlider1, alt: "slider-1", heading: "'AND' IS A DESIGN PHILOSOPHY ENRICHED WITH HISTORICAL KNOWLEDGE OF ART & ARCHITECTURE SYNTHESISED WITH MODERN QUIRK." },
        { id: 2, picture: ProjectTwoSlider2, alt: "slider-2", heading: "'AND' IS A DESIGN PHILOSOPHY ENRICHED WITH HISTORICAL KNOWLEDGE OF ART & ARCHITECTURE SYNTHESISED WITH MODERN QUIRK." },
        { id: 3, picture: ProjectTwoSlider3, alt: "slider-3", heading: "'AND' IS A DESIGN PHILOSOPHY ENRICHED WITH HISTORICAL KNOWLEDGE OF ART & ARCHITECTURE SYNTHESISED WITH MODERN QUIRK." }
      ],
      mainSliderPictures: [
        { id: 1, picture: ProjectTwo1, alt: "slider-1", heading: "'AND' IS A DESIGN PHILOSOPHY ENRICHED WITH HISTORICAL KNOWLEDGE OF ART & ARCHITECTURE SYNTHESISED WITH MODERN QUIRK." },
        { id: 2, picture: ProjectTwo2, alt: "slider-2", heading: "'AND' IS A DESIGN PHILOSOPHY ENRICHED WITH HISTORICAL KNOWLEDGE OF ART & ARCHITECTURE SYNTHESISED WITH MODERN QUIRK." },
        { id: 3, picture: ProjectTwo3, alt: "slider-3", heading: "'AND' IS A DESIGN PHILOSOPHY ENRICHED WITH HISTORICAL KNOWLEDGE OF ART & ARCHITECTURE SYNTHESISED WITH MODERN QUIRK." },
        { id: 4, picture: ProjectTwo4, alt: "slider-4", heading: "'AND' IS A DESIGN PHILOSOPHY ENRICHED WITH HISTORICAL KNOWLEDGE OF ART & ARCHITECTURE SYNTHESISED WITH MODERN QUIRK." },
        { id: 5, picture: ProjectTwo5, alt: "slider-5", heading: "'AND' IS A DESIGN PHILOSOPHY ENRICHED WITH HISTORICAL KNOWLEDGE OF ART & ARCHITECTURE SYNTHESISED WITH MODERN QUIRK." },
        { id: 6, picture: ProjectTwo6, alt: "slider-6", heading: "'AND' IS A DESIGN PHILOSOPHY ENRICHED WITH HISTORICAL KNOWLEDGE OF ART & ARCHITECTURE SYNTHESISED WITH MODERN QUIRK." }
      ],
    }
  },
  {
    id: 3, picture: Interiors3, url: "/interior-detail/3", text: "SKY VILLA", isCircle: false, video: 0,
    detail: {
      id: 3,
      mainHeading: "SKY VILLA",
      subStrongHeading: "",
      subHeading: "A CLASSICAL DESIGN",
      detailPicture1: DetailTen,
      detailPicture2: ProjectThreeDetailSecond,
      detailPicture3: ProjectThreeDetailSecondMobile,
      content: "A CHARMING VINTAGE AFFAIR",
      subContent: "A maximalist classic English-style interior design that is all about comfort, charm, and abundance. Each element in this interior radiates a vintage flair with its posh fabrics, tapestries, patterned wallpaper, rich colours, flowers and ornamented décor pieces.",
      subContent2: "The entire space is a picturesque evocation of the historic Victorian interiors. The living area of this villa is quintessential of the veteran style with decoratively carved armchairs and ottomans, embroidered cushions, exquisite chandeliers, and finely detailed ceramic vares.",
      video: "",
      middleSliderPictures: [
        { id: 1, picture: ProjectThreeSlider1, alt: "slider-1", heading: "CREATING GRANDEUR CLASSICAL SPACES THAT TRANSCEND TIME AND MELD HISTORICAL ART MOVEMENTS. " },
        { id: 2, picture: ProjectThreeSlider2, alt: "slider-2", heading: "CREATING GRANDEUR CLASSICAL SPACES THAT TRANSCEND TIME AND MELD HISTORICAL ART MOVEMENTS. " },
        { id: 3, picture: ProjectThreeSlider3, alt: "slider-3", heading: "CREATING GRANDEUR CLASSICAL SPACES THAT TRANSCEND TIME AND MELD HISTORICAL ART MOVEMENTS. " }
      ],
      mainSliderPictures: [
        { id: 1, picture: ProjectThree1, alt: "slider-1", heading: "CREATING GRANDEUR CLASSICAL SPACES THAT TRANSCEND TIME AND MELD HISTORICAL ART MOVEMENTS. " },
        { id: 2, picture: ProjectThree2, alt: "slider-2", heading: "CREATING GRANDEUR CLASSICAL SPACES THAT TRANSCEND TIME AND MELD HISTORICAL ART MOVEMENTS. " },
        { id: 3, picture: ProjectThree3, alt: "slider-3", heading: "CREATING GRANDEUR CLASSICAL SPACES THAT TRANSCEND TIME AND MELD HISTORICAL ART MOVEMENTS. " },
        { id: 4, picture: ProjectThree4, alt: "slider-4", heading: "CREATING GRANDEUR CLASSICAL SPACES THAT TRANSCEND TIME AND MELD HISTORICAL ART MOVEMENTS. " },
        { id: 5, picture: ProjectThree5, alt: "slider-5", heading: "CREATING GRANDEUR CLASSICAL SPACES THAT TRANSCEND TIME AND MELD HISTORICAL ART MOVEMENTS. " }
      ],
    }
  },
  {
    id: 4, picture: Interiors4, url: "/interior-detail/4", text: "", isCircle: true, video: 1,
    detail: {
      id: 4,
      mainHeading: "THE ETERNAL SYMPHONY",
      subStrongHeading: "",
      subHeading: "A MODERN DESIGN",
      detailPicture1: DetailThree,
      detailPicture3: ProjectFourDetailSecondMobile,
      detailPicture2: ProjectFourDetailSecond,
      content: "TRANQUILITY & BALANCE BORN OUT OF THOUGHTFUL PHILOSOPHY",
      subContent: "For us, ‘Eternal Symphony’ is an interior that makes a bold statement of being a contemporary design with its simplicity, subtle sophistication, deliberate use of texture, and clean lines. Each detail in this interior has a magisterial character and yet they almost melt into one another with the unifying neutral tone of the walls. We punched up the palette and accented the space with some bright and bold colours such as the plush blue chairs that are placed in the space partitioning the living and dining area, an inscribed gold wall installation behind the bar counter, etc.",
      subContent2: "Without being maximalists, we also added a few elements such as the mandala wallpaper in the living area and a door that is tinted with movie posters in the theatre room to add an eclectic touch to the house.",

      video: "",
      middleSliderPictures: [
        { id: 1, picture: ProjectFourSlider1, alt: "slider-1", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." },
        { id: 2, picture: ProjectFourSlider2, alt: "slider-2", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." },
        { id: 3, picture: ProjectFourSlider3, alt: "slider-3", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." }
      ],
      mainSliderPictures: [
        { id: 1, picture: ProjectFour1, alt: "slider-1", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." },
        { id: 2, picture: ProjectFour2, alt: "slider-2", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." },
        { id: 3, picture: ProjectFour3, alt: "slider-3", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." },
        { id: 4, picture: ProjectFour4, alt: "slider-4", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." },
        { id: 5, picture: ProjectFour5, alt: "slider-5", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." },
        { id: 6, picture: ProjectFour6, alt: "slider-6", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." }
      ],
    }
  },
  {
    id: 5, picture: Interiors5, url: "/interior-detail/5", text: "THE ETERNAL SYMPHONY", isCircle: false, video: 0,
    detail: {
      id: 5,
      mainHeading: "THE ETERNAL SYMPHONY",
      subStrongHeading: "",
      subHeading: "A CONTEMPORARY DESIGN",
      detailPicture1: DetailThree,
      detailPicture3: ProjectFourDetailSecondMobile,
      detailPicture2: ProjectFourDetailSecond,
      content: "TRANQUILITY & BALANCE BORN OUT OF THOUGHTFUL PHILOSOPHY",
      subContent: "In our visionary creation, 'Eternal Symphony' emerges as a testament to contemporary design, boldly articulating its essence through a harmonious blend of simplicity, subtle sophistication, deliberate use of texture, and clean lines. Within this immersive interior, each detail assumes a magisterial character, yet miraculously converges into a seamless unity, dancing in tandem with the neutral elegance of the walls. Notably, plush blue chairs take centre stage, strategically positioned to partition the living and dining spaces, offering a visual symphony of dynamic contrasts. The bar area takes on an enchanting allure with an opulent gold wall installation, graciously inscribed and illuminated by the mesmerising glow of backlit onyx, casting an ethereal aura.",
      subContent2: "Yet, our approach extends beyond mere aesthetics. In a delicate dance between restraint and extravagance, we introduced subtle maximalist elements to enrich the narrative. Witness the living area adorned with a captivating mandala wallpaper, a visual ode to intricate beauty, and the theatre room, meanwhile, boasts a door adorned with tinted movie posters, inviting a touch of nostalgia and whimsy into the immersive tapestry of the house.",
      subContent3: "'Eternal Symphony' is not merely a dwelling; it is an eloquent manifestation of weaving together a timeless narrative where every element plays a pivotal role in orchestrating a symphony of thoughtful philosophy and avant-garde tranquillity",

      video: ContentVideo5,
      middleSliderPictures: [
        { id: 1, picture: ProjectFourSlider1, alt: "slider-1", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." },
        { id: 2, picture: ProjectFourSlider2, alt: "slider-2", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." },
        { id: 3, picture: ProjectFourSlider3, alt: "slider-3", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." }
      ],
      mainSliderPictures: [
        { id: 1, picture: ProjectFour1, alt: "slider-1", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." },
        { id: 2, picture: ProjectFour2, alt: "slider-2", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." },
        { id: 3, picture: ProjectFour3, alt: "slider-3", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." },
        { id: 4, picture: ProjectFour4, alt: "slider-4", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." },
        { id: 5, picture: ProjectFour5, alt: "slider-5", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." },
        { id: 6, picture: ProjectFour6, alt: "slider-6", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." }
      ],
    }
  },
  {
    id: 6, picture: Interiors6, url: "/interior-detail/6", text: "PLUME OF PARADISE", isCircle: false, video: 0,
    detail: {
      id: 6,
      mainHeading: "PLUME OF PARADISE",
      subStrongHeading: "",
      subHeading: "A CONTEMPORARY DESIGN",
      detailPicture1: DetailFour,
      detailPicture3: ProjectFiveDetailSecondMobile,
      detailPicture2: ProjectFiveDetailSecond,
      content: "ARTISTRY & ALLURING",
      subContent: "Plume of Paradise is a whimsical, dramatic yet elegant design that is artistic and alluring. A breathtaking tableau of enchantment, drama, and elegance unfurls like a tapestry of dreams. As you step into this haven, it's as though you've entered a realm of reverie, where spaces meld and merge, transcending the boundaries between reality and whimsy. The design of this residence embodies a harmonious symphony of creativity, skillfully exploring scales, proportions, patterns, and colours to redefine the essence of the environment. A distinctive touch is added with an anomalous apple gracing the stairway, playfully nodding to the surreal, while a cleverly disguised false library reveals a clandestine poker table, inviting you to explore uncharted territories. This thoughtful fusion of artistic expression and functional spaces reflects a professional and engaging approach to residential design.",
      subContent2: "Every corner of this enchanted realm offers a new perspective, from the living area to the dining space, unexpected forms and materials weave their magic, transforming the space into an unparalleled sanctuary. This is no ordinary dwelling; it is a nonpareil experience, a haven of homely paradise where dreams and reality seamlessly entwine.",

      video: ContentVideo6,
      middleSliderPictures: [
        { id: 1, picture: ProjectFiveSlider1, alt: "slider-1", heading: "EACH PROJECT DESIGNED BY ‘AND’ IS AN INTELLECTUALISED ALCHEMY OF TRADITION AND MODERNIZATION." },
        { id: 2, picture: ProjectFiveSlider2, alt: "slider-2", heading: "EACH PROJECT DESIGNED BY ‘AND’ IS AN INTELLECTUALISED ALCHEMY OF TRADITION AND MODERNIZATION." },
        { id: 3, picture: ProjectFiveSlider3, alt: "slider-3", heading: "EACH PROJECT DESIGNED BY ‘AND’ IS AN INTELLECTUALISED ALCHEMY OF TRADITION AND MODERNIZATION." }
      ],
      mainSliderPictures: [
        { id: 1, picture: ProjectFive1, alt: "slider-1", heading: "EACH PROJECT DESIGNED BY ‘AND’ IS AN INTELLECTUALISED ALCHEMY OF TRADITION AND MODERNIZATION." },
        { id: 2, picture: ProjectFive2, alt: "slider-2", heading: "EACH PROJECT DESIGNED BY ‘AND’ IS AN INTELLECTUALISED ALCHEMY OF TRADITION AND MODERNIZATION." },
        { id: 3, picture: ProjectFive3, alt: "slider-3", heading: "EACH PROJECT DESIGNED BY ‘AND’ IS AN INTELLECTUALISED ALCHEMY OF TRADITION AND MODERNIZATION." },
        { id: 4, picture: ProjectFive4, alt: "slider-4", heading: "EACH PROJECT DESIGNED BY ‘AND’ IS AN INTELLECTUALISED ALCHEMY OF TRADITION AND MODERNIZATION." },
        { id: 5, picture: ProjectFive5, alt: "slider-5", heading: "EACH PROJECT DESIGNED BY ‘AND’ IS AN INTELLECTUALISED ALCHEMY OF TRADITION AND MODERNIZATION." },
        { id: 6, picture: ProjectFive6, alt: "slider-6", heading: "EACH PROJECT DESIGNED BY ‘AND’ IS AN INTELLECTUALISED ALCHEMY OF TRADITION AND MODERNIZATION." }
      ],
    }
  },
  {
    id: 7, picture: Interiors7, url: "/interior-detail/7", text: "LE BLANC", isCircle: false, video: 0,
    detail: {
      id: 7,
      mainHeading: "LE BLANC",
      subStrongHeading: "",
      subHeading: "A MODERN DESIGN",
      detailPicture1: DetailFive,
      detailPicture3: ProjectSixDetailSecondMobile,
      detailPicture2: ProjectSixDetailSecond,
      content: "A MEDITATIVE METAPHOR OF BLACK AND WHITE",
      subContent: "The tale of this home stands unruffled by the changing seasons with its timeless charm of black and white. While monochrome is often confined to commercial spaces, art galleries, and hotels, AND breaks the cliché, and explores myriad boundaries beyond the usually bracketed spheres.",
      subContent2: "The living room, in particular, emerges as a bold space where the monochromatic agenda comes to life with unparalleled vibrancy. Commanding attention are two captivating figurines, larger-than-life and curated with precision- stand as whimsical sentinels beside the couches, infusing a playful air as their tale unfolds within the painting on the wall. Frozen in time, the boy and girl become protagonists, transcending the boundaries of mere décor. This space becomes a portal where black and white aren't just colours but vibrant hues waiting to be explored—a sanctuary, embodying a meditative metaphor that takes on a life of its own.",

      video: ContentVideo7,
      middleSliderPictures: [
        { id: 1, picture: ProjectSixSlider1, alt: "slider-1", heading: "AT AND, WE BELIEVE IN EVOKING NEW PERSPECTIVES OF BESPOKE INTERIORS IN OUR EACH PROJECT." },
        { id: 2, picture: ProjectSixSlider2, alt: "slider-2", heading: "AT AND, WE BELIEVE IN EVOKING NEW PERSPECTIVES OF BESPOKE INTERIORS IN OUR EACH PROJECT." },
        { id: 3, picture: ProjectSixSlider3, alt: "slider-3", heading: "AT AND, WE BELIEVE IN EVOKING NEW PERSPECTIVES OF BESPOKE INTERIORS IN OUR EACH PROJECT." }
      ],
      mainSliderPictures: [
        { id: 1, picture: ProjectSix1, alt: "slider-1", heading: "AT AND, WE BELIEVE IN EVOKING NEW PERSPECTIVES OF BESPOKE INTERIORS IN OUR EACH PROJECT." },
        { id: 2, picture: ProjectSix2, alt: "slider-2", heading: "AT AND, WE BELIEVE IN EVOKING NEW PERSPECTIVES OF BESPOKE INTERIORS IN OUR EACH PROJECT." },
        { id: 3, picture: ProjectSix3, alt: "slider-3", heading: "AT AND, WE BELIEVE IN EVOKING NEW PERSPECTIVES OF BESPOKE INTERIORS IN OUR EACH PROJECT." },
        { id: 4, picture: ProjectSix4, alt: "slider-4", heading: "AT AND, WE BELIEVE IN EVOKING NEW PERSPECTIVES OF BESPOKE INTERIORS IN OUR EACH PROJECT." },
        { id: 5, picture: ProjectSix5, alt: "slider-5", heading: "AT AND, WE BELIEVE IN EVOKING NEW PERSPECTIVES OF BESPOKE INTERIORS IN OUR EACH PROJECT." },
        { id: 6, picture: ProjectSix6, alt: "slider-6", heading: "AT AND, WE BELIEVE IN EVOKING NEW PERSPECTIVES OF BESPOKE INTERIORS IN OUR EACH PROJECT." }
      ],
    }
  },
  {
    id: 8, picture: Interiors8, url: "/interior-detail/8", text: "ELITE SHOWROOM", isCircle: false, video: 0,
    detail: {
      id: 8,
      mainHeading: "ELITE SHOWROOM",
      // subStrongHeading: "modern/",
      // subHeading: "contemporary",
      detailPicture1: DetailSix,
      detailPicture3: ProjectSevenDetailSecondMobile,
      detailPicture2: ProjectSevenDetailSecond,
      content: "OPULENCE, GRACE AND BEAUTY",
      subContent: "Nestled in Chennai's vibrant commercial district, the Sarvana Elite jewelry store stands as a lavish 18,600-square-foot masterpiece. The challenge was crystal clear: design an interior that magnifies the prestige of its displayed jewelry, and create an exterior that emanates grandeur. The exteriors of Sarvana Elite showcase an impressive facade that invites onlookers into a luxurious world beyond its doors, expressing a sense of bold regality. We opted for solid zinc, crowned with a burnt umber pyramidical finish, to radiate its unwavering presence.",
      subContent2: "For this project, antique classical inspiration guided us to grace the space with ornate furniture, opulent upholstery, tasteful chandeliers, and exquisite stone-clad walls. As one ascends through each level, the design's grandeur blossoms, mirroring the splendor of gold, diamond, and platinum collections. Our mission: to make the interior a resounding accolade to the jewelry itself. This project reflects our dedication to creating spaces that go beyond mere aesthetics. They tell a story, evoke emotions, and offer a sensory experience where each visitor can enjoy opulence and grace. Our goal is to surpass the ordinary, turning opulence and grace from words into tangible sensations for our valued clientele.",

      video: ContentVideo8,
      middleSliderPictures: [
        { id: 1, picture: ProjectSevenSlider1, alt: "slider-1", heading: "FROM CONCEPT THROUGH TO COMPLETION, WE ARE PASSIONATE ABOUT WHAT WE DO." },
        { id: 2, picture: ProjectSevenSlider2, alt: "slider-2", heading: "FROM CONCEPT THROUGH TO COMPLETION, WE ARE PASSIONATE ABOUT WHAT WE DO." },
        { id: 3, picture: ProjectSevenSlider3, alt: "slider-3", heading: "FROM CONCEPT THROUGH TO COMPLETION, WE ARE PASSIONATE ABOUT WHAT WE DO." }
      ],
      mainSliderPictures: [
        { id: 1, picture: ProjectSeven1, alt: "slider-1", heading: "FROM CONCEPT THROUGH TO COMPLETION, WE ARE PASSIONATE ABOUT WHAT WE DO." },
        { id: 2, picture: ProjectSeven2, alt: "slider-2", heading: "FROM CONCEPT THROUGH TO COMPLETION, WE ARE PASSIONATE ABOUT WHAT WE DO." },
        { id: 3, picture: ProjectSeven3, alt: "slider-3", heading: "FROM CONCEPT THROUGH TO COMPLETION, WE ARE PASSIONATE ABOUT WHAT WE DO." },
        { id: 4, picture: ProjectSeven4, alt: "slider-4", heading: "FROM CONCEPT THROUGH TO COMPLETION, WE ARE PASSIONATE ABOUT WHAT WE DO." },
        { id: 5, picture: ProjectSeven5, alt: "slider-5", heading: "FROM CONCEPT THROUGH TO COMPLETION, WE ARE PASSIONATE ABOUT WHAT WE DO." },
        { id: 6, picture: ProjectSeven6, alt: "slider-6", heading: "FROM CONCEPT THROUGH TO COMPLETION, WE ARE PASSIONATE ABOUT WHAT WE DO." }
      ],
    }
  },
  {
    id: 9, picture: Interiors9, url: "/interior-detail/9", text: "CHAPTERS", isCircle: false, video: 0,
    detail: {
      id: 9,
      mainHeading: "CHAPTERS",
      subStrongHeading: "",
      subHeading: "A MODERN DESIGN",
      detailPicture1: DetailSeven,
      detailPicture2: ProjectEightDetailSecond,
      detailPicture3: ProjectEightDetailSecondMobile,
      content: "A MODERN NARRATIVE TO HOME",
      subContent: "A project of this kind graces our path once in a rare celestial rhyme. Holder of many accolades, renowned for its remarkable achievement in uniting the five elemental forces of nature - Bhumi (Earth), Jal (Water), Tejas (Fire), Vayu (Air), and Vyom (Space), Chapters’ free-flowing concept narrates a poetic symphony where creativity knows no bounds. A singular, resolute nod of enthusiasm from the youthful couple was all that was needed to grant us the artistic autonomy necessary to craft this magnificent and extraordinary creation.",
      subContent2: "Amidst the encompassing ambience of Indore, this sprawling half-acre haven reveals a poetic odyssey. At its core lies a central courtyard, seamlessly blending indoor and outdoor spaces through expansive glass walls. Every corner is adorned with meticulously curated art, collectively crafting an effulgent and enchanting realm. The countenance of David graces the living area, while a sculpture of a young boy poised at the brink of the swimming pool imparts a whimsical and vitalizing essence to the serene waters.",

      video: "",
      middleSliderPictures: [
        { id: 1, picture: ProjectEightSlider1, alt: "slider-1", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." },
        { id: 2, picture: ProjectEightSlider2, alt: "slider-2", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." },
        { id: 3, picture: ProjectEightSlider3, alt: "slider-3", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." }
      ],
      mainSliderPictures: [
        { id: 1, picture: ProjectEight1, alt: "slider-1", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." },
        { id: 2, picture: ProjectEight2, alt: "slider-2", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." },
        { id: 3, picture: ProjectEight3, alt: "slider-3", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." },
        { id: 4, picture: ProjectEight4, alt: "slider-4", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." },
        { id: 5, picture: ProjectEight5, alt: "slider-5", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." },
        { id: 6, picture: ProjectEight6, alt: "slider-6", heading: "HELPING OUR CLIENTS TO CREATE EXTRAORDINARY HOME INTERIORS THAT EXHIBIT HARMONY AND JOY." }
      ],
    }
  },
  {
    id: 10, picture: Interiors10, url: "/interior-detail/10", text: "RUSTIC REFLECTIONS", isCircle: false, video: 0,
    detail: {
      id: 10,
      mainHeading: "RUSTIC REFLECTIONS",
      subStrongHeading: "",
      subHeading: "A PAST RESONATING THE PRESENT",
      detailPicture1: DetailEight,
      detailPicture2: ProjectNineDetailSecond,
      detailPicture3: ProjectNineDetailSecondMobile,
      content: "A SPACE ENRICHED WITH ETHNIC NOSTALGIA",
      subContent: "Embarking on a journey through Rustic Reflection feels like entering a tale of delicate sophistication and cherished beliefs. From the exterior marked by geometric precision and a clean aesthetic to the interior; a dynamic portrayal of modern-day ideas chaperons with elements of the traditional past knitted together to create the ambience of ritziness and warm comfort.",
      subContent2: "This house truly is a visceral portrayal of the love we have for creating contemporary interiors that have deep roots in ethnicity branching into modern perspectives. At the heart of the project's interior lies a masterfully crafted piece—an artisan's labour of love on the door of the Pooja room. This door boasts a unique design, fashioned from the enchanting blue malachite, a semi-precious stone, and adorned with antique brass door frames.",
      subContent3: "Beyond its aesthetic allure, the door serves as a captivating visual narrative of Jainism beliefs, artfully depicting scenes inspired by Mahavir's visionary dreams and his profound connection to the Jain faith. As the clients express their happiness in seeing their faith mirrored in the door, Rustic Reflection becomes not just a dwelling but a heartfelt expression of familial devotion and cultural pride.",
      video: "",
      middleSliderPictures: [
        { id: 1, picture: ProjectNineSlider1, alt: "slider-1", heading: "REIMAGINE YOUR DREAM HOUSE WITH EXPERIENCED TEAM OF AND DESIGN CO." },
        { id: 2, picture: ProjectNineSlider2, alt: "slider-2", heading: "REIMAGINE YOUR DREAM HOUSE WITH EXPERIENCED TEAM OF AND DESIGN CO." },
        { id: 3, picture: ProjectNineSlider3, alt: "slider-3", heading: "REIMAGINE YOUR DREAM HOUSE WITH EXPERIENCED TEAM OF AND DESIGN CO." }
      ],
      mainSliderPictures: [
        { id: 1, picture: ProjectNine1, alt: "slider-1", heading: "REIMAGINE YOUR DREAM HOUSE WITH EXPERIENCED TEAM OF AND DESIGN CO." },
        { id: 2, picture: ProjectNine2, alt: "slider-2", heading: "REIMAGINE YOUR DREAM HOUSE WITH EXPERIENCED TEAM OF AND DESIGN CO." },
        { id: 3, picture: ProjectNine3, alt: "slider-3", heading: "REIMAGINE YOUR DREAM HOUSE WITH EXPERIENCED TEAM OF AND DESIGN CO." },
        { id: 4, picture: ProjectNine4, alt: "slider-4", heading: "REIMAGINE YOUR DREAM HOUSE WITH EXPERIENCED TEAM OF AND DESIGN CO." },
        { id: 5, picture: ProjectNine5, alt: "slider-5", heading: "REIMAGINE YOUR DREAM HOUSE WITH EXPERIENCED TEAM OF AND DESIGN CO." },
        { id: 6, picture: ProjectNine6, alt: "slider-6", heading: "REIMAGINE YOUR DREAM HOUSE WITH EXPERIENCED TEAM OF AND DESIGN CO." }
      ],
    }
  },
  {
    id: 11, picture: Interiors11, url: "/interior-detail/11", text: "URBAN RHAPSODY", isCircle: false, video: 0,
    detail: {
      id: 11,
      mainHeading: "URBAN RHAPSODY",
      subStrongHeading: "",
      subHeading: "A CONTEMPORARY DESIGN",
      detailPicture1: DetailNine,
      detailPicture2: ProjectTenDetailSecond,
      detailPicture3: ProjectTenDetailSecondMobile,
      content: "SOBER SENSUALITY OF NEUTRAL AND GOLD",
      subContent: "At AND, we draw inspiration and marvel at the evolution of art movements through the ages. This project endeavours a mystical interplay of cubic elements, interwoven with the understated sensuality of neutrals and gold. The interior unfolds as a harmonious marriage of geometric precision and functional elegance, dancing in concert with the fluidity of earthy tones.",
      subContent2: "Art becomes the palette that infuses spaces with vibrant hues, while the rest of the interior unfolds like a melodious composition of whites and soft pastel tones. Each painting becomes a nuanced expression, capturing a myriad of viewpoints to convey the essence of the subject within a broader context—a thematic echo of the cubic movement's core.",
      subContent3: "Moreover, keen observers will discover a subtle yet resplendent glitz in the meticulously incorporated gold details. These golden accents, strategically placed throughout the interiors, serve as delicate embellishments that amplify the overall ambiance of the residence. In essence, this project is not merely a design endeavour; it is a visual symphony that resonates with the timeless echoes of art movements, bringing forth an eloquent fusion of form, function, and artistic expression.",

      video: ContentVideo11,
      middleSliderPictures: [
        { id: 1, picture: ProjectTenSlider1, alt: "slider-1", heading: "GIVE YOUR SPACE A CLASSIC & TIMELESS CONTEMPORARY INTERIORS." },
        { id: 2, picture: ProjectTenSlider2, alt: "slider-2", heading: "GIVE YOUR SPACE A CLASSIC & TIMELESS CONTEMPORARY INTERIORS." },
        { id: 3, picture: ProjectTenSlider3, alt: "slider-3", heading: "GIVE YOUR SPACE A CLASSIC & TIMELESS CONTEMPORARY INTERIORS." },
        { id: 4, picture: ProjectTenSlider4, alt: "slider-4", heading: "GIVE YOUR SPACE A CLASSIC & TIMELESS CONTEMPORARY INTERIORS." }
      ],
      mainSliderPictures: [
        { id: 1, picture: ProjectTen1, alt: "slider-1", heading: "GIVE YOUR SPACE A CLASSIC & TIMELESS CONTEMPORARY INTERIORS." },
        { id: 2, picture: ProjectTen2, alt: "slider-2", heading: "GIVE YOUR SPACE A CLASSIC & TIMELESS CONTEMPORARY INTERIORS." },
        { id: 3, picture: ProjectTen3, alt: "slider-3", heading: "GIVE YOUR SPACE A CLASSIC & TIMELESS CONTEMPORARY INTERIORS." },
        { id: 4, picture: ProjectTen4, alt: "slider-4", heading: "GIVE YOUR SPACE A CLASSIC & TIMELESS CONTEMPORARY INTERIORS." },
        { id: 5, picture: ProjectTen5, alt: "slider-5", heading: "GIVE YOUR SPACE A CLASSIC & TIMELESS CONTEMPORARY INTERIORS." },
        { id: 6, picture: ProjectTen6, alt: "slider-6", heading: "GIVE YOUR SPACE A CLASSIC & TIMELESS CONTEMPORARY INTERIORS." }
      ],
    }
  },
  {
    id: 12, picture: Interiors12, url: "/interior-detail/12", text: "THE REGAL ODYSSEY", isCircle: false, video: 0,
    detail: {
      id: 12,
      mainHeading: "THE REGAL ODYSSEY",
      subStrongHeading: "",
      subHeading: "A NEO-CLASSICAL DESIGN",
      detailPicture1: "",
      detailPicture2: ProjectElevenDetailSecond,
      detailPicture3: ProjectElevenDetailSecondMobile,
      content: "IMMACULATELY REGAL",
      subContent: "A palette of aristocratic colors- gold, champagne, and silver- has been deftly wielded to enhance the aura of sophistication of this bungalow’s interior. The interiors stand as an exuberant miracle where our focus transcended the mere aesthetic to underscore the twin values of sumptuous luxury and profound comfort.",
      subContent2: "The elements of royal environs unfold through the glimmering chandeliers, sensuous satin upholstery, and imperial wallpapers where the interiors whisper an immaculate regality. With Regal Odyssey, our intention was not to merely design a space but to curate an experience, a majestic quest that invites one to bask in the lap of resplendent royalty.",

      video: "",
      middleSliderPictures: [
        { id: 1, picture: ProjectElevenSlider1, alt: "slider-1", heading: "EMBRACE LUXURY WITH OUR NEOCLASSICAL INTERIOR DESIGNS FOR YOUR HOME." },
        { id: 2, picture: ProjectElevenSlider2, alt: "slider-2", heading: "EMBRACE LUXURY WITH OUR NEOCLASSICAL INTERIOR DESIGNS FOR YOUR HOME." },
        { id: 3, picture: ProjectElevenSlider3, alt: "slider-3", heading: "EMBRACE LUXURY WITH OUR NEOCLASSICAL INTERIOR DESIGNS FOR YOUR HOME." }
      ],
      mainSliderPictures: [
        { id: 1, picture: ProjectEleven1, alt: "slider-1", heading: "EMBRACE LUXURY WITH OUR NEOCLASSICAL INTERIOR DESIGNS FOR YOUR HOME." },
        { id: 2, picture: ProjectEleven2, alt: "slider-2", heading: "EMBRACE LUXURY WITH OUR NEOCLASSICAL INTERIOR DESIGNS FOR YOUR HOME." },
        { id: 3, picture: ProjectEleven3, alt: "slider-3", heading: "EMBRACE LUXURY WITH OUR NEOCLASSICAL INTERIOR DESIGNS FOR YOUR HOME." }
      ],
    }
  },
  {
    id: 13, picture: Interiors13, url: "/interior-detail/13", text: "SHANTI BANARAS", isCircle: false, video: 0,
    detail: {
      id: 13,
      mainHeading: "SHANTI BANARAS",
      subStrongHeading: "",
      subHeading: "A COUTURE CRAFTSMANSHIP",
      detailPicture1: DetailThirteen,
      detailPicture2: ProjectTwelveDetailSecond,
      detailPicture3: ProjectTwelveDetailSecondMobile,
      content: "ENDEARING FACETS OF INDIAN AUTHENTICITY.",
      subContent: "Situated in the distinguished locale of Kala Ghoda, the conceptualization and design implementation for the Shanti Banaras store in Mumbai constituted a comprehensive and thoughtfully orchestrated endeavour. Our main goal was to create an integrated interior that seamlessly blended contemporary shelving with classical accents. We specifically emphasised incorporating traditional carvings to evoke a profound sense of history and culture, epitomising the essence of the iconic Indian brand.",
      subContent2: "Every design element contributes depth and meaning to the space, establishing a tangible connection to Banaras's rich heritage in textiles and craftsmanship.",
      subContent3: "To infuse an added layer of opulence, gold detailing was incorporated, elevating the overall ambience to exude high-end sophistication and exclusivity. The integration of the architectural elements of the arched balconies into the new design scheme is a testament to our commitment to preserving the original character and charm of the space while enhancing its overall aesthetic.",

      video: ContentVideo13,
      middleSliderPictures: [
        { id: 1, picture: ProjectTwelveSlider1, alt: "slider-1", heading: "CRAFTING A NEW SAGA FOR INDIAN COUTURE WHILE EMBRACING THE TRADITIONAL AUTHENTICITY" },
        { id: 2, picture: ProjectTwelveSlider2, alt: "slider-2", heading: "CRAFTING A NEW SAGA FOR INDIAN COUTURE WHILE EMBRACING THE TRADITIONAL AUTHENTICITY" },
        { id: 3, picture: ProjectTwelveSlider3, alt: "slider-3", heading: "CRAFTING A NEW SAGA FOR INDIAN COUTURE WHILE EMBRACING THE TRADITIONAL AUTHENTICITY" }
      ],
      mainSliderPictures: [
        { id: 1, picture: ProjectTwelve1, alt: "slider-1", heading: "CRAFTING A NEW SAGA FOR INDIAN COUTURE WHILE EMBRACING THE TRADITIONAL AUTHENTICITY" },
        { id: 2, picture: ProjectTwelve2, alt: "slider-2", heading: "CRAFTING A NEW SAGA FOR INDIAN COUTURE WHILE EMBRACING THE TRADITIONAL AUTHENTICITY" },
        { id: 3, picture: ProjectTwelve3, alt: "slider-3", heading: "CRAFTING A NEW SAGA FOR INDIAN COUTURE WHILE EMBRACING THE TRADITIONAL AUTHENTICITY" }
      ],
    }
  },
  {
    id: 14, picture: Interiors14, url: "/interior-detail/14", text: "LEGENDS OF AUROUS", isCircle: false, video: 0,
    detail: {
      id: 14,
      mainHeading: "LEGENDS OF AUROUS",
      subStrongHeading: "",
      subHeading: "A MODERN WORKSPACE",
      detailPicture1: "",
      detailPicture2: ProjectThirtyDetailSecond,
      detailPicture3: ProjectThirtyDetailSecondMobile,
      content: "EFFICIENT AND PRAGMATIC",
      subContent: "Entering Kochar Homes introduces you to a contemporary workspace that echoes a culture of progress and momentum, unfolding within an expansive 8000 sq. ft. canvas. The space is thoughtfully designed to encapsulate the essence of a forward-thinking environment, fostering a dynamic and professional atmosphere. The orchestration of design compositions in this space dances seamlessly to convey clarity, personality, and quality, effectively communicating the essence of the company. The sophisticated ambience of the area exudes a formal demeanour, highlighted by the commanding presence of the canary yellow reception desk and captivating artworks that boldly declare Kochar Homes' distinctive tone. Rich contrasts and luxurious finishes conclude with statuario flooring, neutral tones, and wooden furniture pirouetting gracefully, bestowing continuity upon an initially odd layout.",
      subContent2: "",

      video: "",
      middleSliderPictures: [
        { id: 1, picture: ProjectThirtySlider1, alt: "slider-1", heading: "CRAFTING A NEW SAGA FOR INDIAN COUTURE WHILE EMBRACING THE TRADITIONAL AUTHENTICITY. " },
        { id: 2, picture: ProjectThirtySlider2, alt: "slider-2", heading: "CRAFTING A NEW SAGA FOR INDIAN COUTURE WHILE EMBRACING THE TRADITIONAL AUTHENTICITY. " },
        { id: 3, picture: ProjectThirtySlider3, alt: "slider-3", heading: "CRAFTING A NEW SAGA FOR INDIAN COUTURE WHILE EMBRACING THE TRADITIONAL AUTHENTICITY. " }
      ],
      mainSliderPictures: [
        { id: 1, picture: ProjectThirty1, alt: "slider-1", heading: "CRAFTING A NEW SAGA FOR INDIAN COUTURE WHILE EMBRACING THE TRADITIONAL AUTHENTICITY. " },
        { id: 2, picture: ProjectThirty2, alt: "slider-2", heading: "CRAFTING A NEW SAGA FOR INDIAN COUTURE WHILE EMBRACING THE TRADITIONAL AUTHENTICITY." },
        { id: 3, picture: ProjectThirty3, alt: "slider-3", heading: "CRAFTING A NEW SAGA FOR INDIAN COUTURE WHILE EMBRACING THE TRADITIONAL AUTHENTICITY." }
      ],
    }
  },
];
