import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import About from './pages/About/About';
import Clientele from './pages/Clientele/Clientele';
import Press from './pages/Press/Press';
import Interiors from './pages/Interiors/Interiors';
import InteriorsDetail from './pages/InteriorsDetail/InteriorsDetail';
import Architecture from './pages/Architecture/Architecture';



const MainRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/clientele">
          <Clientele />
        </Route>
        <Route path="/press-and-collabs">
          <Press />
        </Route>
        <Route path="/interiors">
          <Interiors />
        </Route>
		<Route path="/interior-detail/:id">
			<InteriorsDetail />
		</Route>
		<Route path="/architecture">
			<Architecture />
        </Route>
  </Switch>
    </Router>
  );
};
export default MainRouter;
