import {
  withWidth
} from "@material-ui/core";
import { styles as useStyles } from "./styles";
import HoverVideoPlayer from 'react-hover-video-player';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Video1 from "../../../assets/video/video1.mp4";
import Video2 from "../../../assets/video/video2.mp4";
import Video3 from "../../../assets/video/video3.mp4";
import Watch from "../../../assets/images/watch.svg";
import WatchHover from "../../../assets/images/watchHover.svg";


const WhySection = (props) => {
  const classes = useStyles();

  return (

    <div className={classes.root}>

      <Container>
        <Row className="justify-content-center">
          <Col xs={12} className="text-center">
            <h2 className={classes.h2}> why choose and? </h2>
          </Col>
          <Col md={7} className="mt-1 px-4">
            <Row className="justify-content-md-center">
              <Col className="px-05">
                <div className={classes.relative}>
                  <HoverVideoPlayer
                    className={classes.imgobject}
                    videoSrc={Video1}
                    muted={false}
                    loadingOverlay={
                      <div className="loading-overlay">
                        <div className="loading-spinner" />
                      </div>
                    }
                  />

                  <p className={classes.p}><strong>Paulo Bleve</strong> <br /> IFDM Chief editor & founder</p>
                </div>
              </Col>
              <Col className="px-05">
                <div className={classes.relative}>
                  <HoverVideoPlayer
                    className={classes.imgobject}
                    videoSrc={Video2}
                    muted={false}
                    loadingOverlay={
                      <div className="loading-overlay">
                        <div className="loading-spinner" />
                      </div>
                    }
                  />

                  <p className={classes.p}><strong>Trivikram Srinivas</strong> <br /> Film Director</p>
                </div>
              </Col>
              <Col className="px-05">
                <div className={classes.relative}>
                  <HoverVideoPlayer
                    className={classes.imgobject}
                    videoSrc={Video3}
                    muted={false}
                    loadingOverlay={
                      <div className="loading-overlay">
                        <div className="loading-spinner" />
                      </div>
                    }
                  />

                  <p className={classes.p}><strong>Architect Cristina Maggiolo  </strong> <br /> BelCor Interiors</p>
                </div>
              </Col>
            </Row>


            <a href="/clientele#whyfull" className="CtaBtn mt-4">
              <img src={Watch} alt="" className="Btn" />
              <img src={WatchHover} alt="" className="BtnHover" />
            </a>

          </Col></Row></Container>

    </div>

  );
};
export default withWidth()(WhySection);
