import {
  withWidth
} from "@material-ui/core";
import { styles as useStyles } from "./styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import About1 from "../../../assets/images/about/SliderImages/image1.jpg";
import About2 from "../../../assets/images/about/SliderImages/image2.jpg";
import About3 from "../../../assets/images/about/SliderImages/image3.jpg";
import About4 from "../../../assets/images/about/SliderImages/image4.png";
import About5 from "../../../assets/images/about/SliderImages/image5.png";
import About6 from "../../../assets/images/about/SliderImages/image6.jpg";
import About7 from "../../../assets/images/about/SliderImages/image7.jpg";
import About8 from "../../../assets/images/about/SliderImages/image8.jpg";
import About9 from "../../../assets/images/about/SliderImages/image9.jpg";
import About10 from "../../../assets/images/about/SliderImages/image10.jpg";
import About11 from "../../../assets/images/about/SliderImages/image11.jpg";
import About12 from "../../../assets/images/about/SliderImages/image12.jpg";


const AboutImage = (props) => {
  const classes = useStyles();

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    cssEase: "ease-in-out",
    autoplay: true,
    speed: 50,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (

    <div className={classes.root}>
      <Container>
        <Row className="justify-content-center">
          <Col md={10} className="mt-1 px-4 ArrowXl AboutSlider">
            <Slider {...settings}>
              <div className={classes.relative}>
                <img src={About1} alt="" />
                <p className={classes.p}>In creative conversations with <strong className="pe-1">Domenico Dolce</strong> at Milan design week eventcurated by Paolo Bleve ( Publisher of IFDM magazine).</p>
              </div>
              <div className={classes.relative}>
                <img src={About2} alt="" />
                <p className={classes.p}>Rupesh Baid with <strong className="pe-1">Jean Servais Somian</strong> at Art Basel, 2022</p>
              </div>
              <div className={classes.relative}>
                <img src={About3} alt="" />
                <p className={classes.p}>Archana Baid receiving iGen award by Architect & Interiors India</p>
              </div>
              <div className={classes.relative}>
                <img src={About7} alt="" />
                <p className={classes.p}>Working on a rectangular stained glass ceiling for a private residence with artisans who use the same techniques to produce stained glass today that was used a century ago, by following the same ancient French tradition.</p>
              </div>
              <div className={classes.relative}>
                <img src={About8} alt="" />
                <p className={classes.p}>In conversation with Mr. Umberto Gessi at the Gessi factory</p>
              </div>
              <div className={classes.relative}>
                <img src={About9} alt="" />
                <p className={classes.p}>iDogi award Ceremony, Venice, Italy.</p>
              </div>
              <div className={classes.relative}>
                <img src={About4} alt="" />
                <p className={classes.p}>Sonshine Villa Project awarded as ‘Living Room Project of the year 2022' by Architecture and Interiors, India</p>
              </div>
              <div className={classes.relative}>
                <img src={About5} alt="" />
                <p className={classes.p}>Receiving AD 100 for the Most influential names in architecture and interior design in the Indian subcontinent,the landmark makers category.</p>
              </div>
              <div className={classes.relative}>
                <img src={About6} alt="" />
                <p className={classes.p}>Rupesh Baid as jury member for architectural event hosted by Architect and interiors, India.</p>
              </div>
			  <div className={classes.relative}>
                <img src={About10} alt="" />
                <p className={classes.p}>Archana and Rupesh Baid after receiving the GERMAN DESIGN AWARD SPECIAL 2023 at Frankfurt by the prestigious German Design Council.</p>
              </div>
              <div className={classes.relative}>
                <img src={About11} alt="" />
                <p className={classes.p}>Rupesh Baid in candid moment with other winners of German Design Award.</p>
              </div>
              <div className={classes.relative}>
                <img src={About12} alt="" />
                <p className={classes.p}>Behind the scenes[BTS] for a cover story shot for Architect and Interiors India Magazine.</p>
              </div>
            </Slider>
          </Col>
        </Row>
      </Container>
    </div>

  );
};
export default withWidth()(AboutImage);
