import { makeStyles } from '@material-ui/core/styles';
//import Instagram from "../../assets/images/contactMob.png";

export const styles = makeStyles((theme) => ({
  main: {
    overflow: 'hidden',
    paddingTop: '30px',
    [theme.breakpoints.down("xs")]: {
      paddingTop: '10px',
    },
  },

  h1: {
    fontSize: '40px',
    color: '#060606',
    fontFamily: 'Cormorant Infant',
    lineHeight: '0.8',
    fontWeight: '500',
    textTransform: 'uppercase',
    [theme.breakpoints.down("md")]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "35px",
      textAlign: 'center',
    },
  },

  H6: {
    fontSize: '17px',
    fontWeight: "300",
    marginBottom: '0',
  },
  ClientName: {
    textTransform: 'uppercase',
    letterSpacing: '1px',
    lineHeight: 'Normal',
    fontWeight: '400',
    marginBottom: '0',
    wordBreak: "break-word"
  },
  p: {
    lineHeight: 'normal',
    fontFamily: 'Cormorant Infant',
    marginBottom: '0',
  },

  mY: {
    marginTop: '3rem',
    marginBottom: '3rem',
    [theme.breakpoints.down("xs")]: {
      marginTop: '5rem',
      marginBottom: '5rem',
    }
  },


}));
