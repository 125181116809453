import 'bootstrap/dist/css/bootstrap.min.css';

import MainApp from "./MainApp";
import {theme} from "./theme";

import { ThemeProvider } from '@material-ui/core/styles';

const App=()=> {
  return (
    <ThemeProvider theme={theme}>
      <MainApp/>
    </ThemeProvider>
  );
}

export default App;
