import React, { useEffect, useRef } from "react";
import { withWidth } from "@material-ui/core";
import { styles as useStyles } from "./styles";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DetailOne from "../../../assets/images/architecture/interiorsgreen.mov";
import WOW from "wowjs";


import VITRUVIUS from "../../../assets/images/VITRUVIUS.png";


const DetailContent = ({ detail }) => {
  const classes = useStyles();
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();

  }, []);

  return (
    <div className={classes.root}>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={10}>
            <Row className="align-items-start">
              <div className="text-center mb-4">

                <h2 className={classes.h1}>BEAUTIFUL YET VERSATILE,INNOVATIVE YET TIMELESS, ARCHITECTURAL STYLES FROM ANTIQUITY TO THE PRESENT DAY CONTEMPORARY.</h2>

              </div>
              <Col md={5} className="pe-3 pe-md-5 text-center text-md-start">

                <p className={classes.p}>For us architecture is a design form that has an ability to invoke emotion and stir the senses. Like any great design always serves its purpose, we make sure to enhance the longevity of our architectural designs by focusing on its functionality. We at AND, work with experienced engineers & expert construction partners that work in perfect harmony with each other to bring your dream home to life. We analyze a living space as the fundamental premises and ideas for harmonious living and wellbeing for an individual and collective.</p>
                <p className={classes.p}>A literary and philosophical analysis is what goes into conceptualising each architectural design at AND. From commercial to residential, we rethink every mode of constructivism, the artistic lavishness, as well as the architectonic eclecticism to create objectively beautiful and functional spaces that are beautiful yet versatile, innovative yet timeless.</p>


                <div className="d-flex align-items-center mt-4">
                  <div>
                    <h2 className={classes.h1}>
                      “Firmitas,
                      utilitas,
                      venustas.” </h2>
                    <p className={classes.p}>― Vitruvius</p>
                  </div>
                  <img src={VITRUVIUS} alt="" className={classes.Img} />
                </div>
              </Col>
              <Col md={7} className="ps-3 ps-md-4 mt-5 mt-md-0">
                <video
                  playsInline
                  autoPlay
                  loop
                  muted
                  src={DetailOne}
                  ref={videoEl}
                  className={classes.archVideo}
                />
              </Col>

            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default withWidth()(DetailContent);
