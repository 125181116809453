import React, { useEffect } from "react";
import { withWidth, Hidden } from "@material-ui/core";
import { styles as useStyles } from "./styles";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import DetailOne from "../../../assets/images/interior-detail/image-1.jpg";
//import DetailSecond from "../../../assets/images/interior-detail/section2.jpg";
import WOW from "wowjs";



const DetailContent = ({ detail }) => {
  const classes = useStyles();

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();

  }, []);

  return (
    <div className={classes.root}>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={10}>
            <Row className="align-items-start">
              <Col md={6} className="pe-3 pe-md-5 text-center text-md-start detailContent">
                <p className={classes.h6}>
                  {detail.content}
                </p>
                <p className={classes.p}>{detail.subContent}</p>
                <p className={classes.p}>{detail.subContent2}</p>
                <p className={classes.p}>{detail.subContent3}</p>
                <p className={classes.p}>{detail.subContent4}</p>
                <p className={classes.p}>{detail.subContent5}</p>
                <div className="mt-5 wow fadeInDown">

                  {detail.detailPicture1 && (
                    <img src={detail.detailPicture1} alt="detailjhj1" className="img-fluid" />
                  )}


                </div>
              </Col>
              <Col md={6} className="ps-3 ps-md-4 mt-5 mt-md-0">
                <Hidden xsDown> <img src={detail.detailPicture2} alt="detail2" /></Hidden>
                <Hidden smUp> <img src={detail.detailPicture3} alt="detail3" /></Hidden>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default withWidth()(DetailContent);
