import React, { useEffect, useRef } from "react";
import {
  withWidth
} from "@material-ui/core";
import { styles as useStyles } from "./styles";
import DesktopVideo from "../../../assets/video/NEW_HOMEPAGE.mp4";
import HomeImg from "../../../assets/images/CIRCLE-BELOW-BANNER.png";
import WOW from "wowjs";

const HomeVideo = (props) => {
  const classes = useStyles();
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };
  useEffect(() => {
    attemptPlay();
    new WOW.WOW({
      live: false,
    }).init();

  }, []);
  return (
    <div className={classes.relative}>


      <video
        playsInline
        autoPlay
        loop
        muted
        src={DesktopVideo}
        ref={videoEl}
        className={classes.imgobject}
      />

      <div className={classes.HomeImg} >
        <img src={HomeImg} alt="" className="wow fadeInDown" />
      </div>


    </div>

  );
};
export default withWidth()(HomeVideo);
