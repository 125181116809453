import { makeStyles } from "@material-ui/core/styles";
//import { Autocomplete } from "@material-ui/lab";

export const styles = makeStyles((theme) => ({


    root: {
        marginTop: '85px',
        marginBottom: '35px',

        [theme.breakpoints.down("xs")]: {
            marginTop: '45px',
        },
    },

    h6: {
        fontSize: '16px',
        textTransform: 'uppercase',
        fontFamily: 'Cormorant Infant',
        lineHeight: '1',
        fontWeight: '600',
        marginBottom: '15px',
        [theme.breakpoints.down("xs")]: {
            fontSize: '14px',
        },
    },

    p: {
        fontSize: "16px",
        fontFamily: 'Cormorant Infant',
        lineHeight: '1',
        fontWeight: "500",
        marginTop: '0px'
    },
    archVideo: {
        width: '100%',
        height: 'auto',
    }
}));
