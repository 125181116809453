import React, { useEffect } from "react";
import {
  withWidth
} from "@material-ui/core";
import { styles as useStyles } from "./styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Watch from "../../../assets/images/watch.svg";
import WatchHover from "../../../assets/images/watchHover.svg";
import Insta1 from "../../../assets/images/instafeed/pic1.jpg";
import Insta2 from "../../../assets/images/instafeed/pic2.jpg";
import Insta3 from "../../../assets/images/instafeed/pic3.jpg";
import Insta4 from "../../../assets/images/instafeed/pic4.jpg";
import Insta5 from "../../../assets/images/instafeed/pic5.jpg";
import Insta6 from "../../../assets/images/instafeed/pic6.jpg";
import Insta7 from "../../../assets/images/instafeed/pic7.jpg";
import Insta8 from "../../../assets/images/instafeed/pic8.jpg";
import Insta9 from "../../../assets/images/instafeed/pic9.jpg";
import Insta10 from "../../../assets/images/instafeed/pic10.jpg";
import Insta11 from "../../../assets/images/instafeed/pic11.jpg";
import Insta12 from "../../../assets/images/instafeed/pic12.jpg";
import HomeImg from "../../../assets/images/contactVitru.png";
import WOW from "wowjs";

const HomeVideo = (props) => {
  const classes = useStyles();
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();

  }, []);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 900,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className={classes.root}>
      <div className={classes.HomeImg} >
        <img src={HomeImg} alt="" className="wow fadeInDown" />
      </div>
      <Container>
        <Row className="justify-content-center mt-5">
          <Col xs={12} className="text-center">
            <h2 className={classes.h2}> explore us on instagram & pinterest </h2>
          </Col>
          <Col md={9} className="mt-1 pt-2 pt-sm-2 InstaSlider">
            <Slider {...settings}>
              <div className={classes.InstaFeed}>
                <img src={Insta1} alt="insta1" />
              </div>
              <div className={classes.InstaFeed}>
                <img src={Insta2} alt="insta2" />
              </div>
              <div className={classes.InstaFeed}>
                <img src={Insta3} alt="insta3" />
              </div>
              <div className={classes.InstaFeed}>
                <img src={Insta4} alt="insta4" />
              </div>
              <div className={classes.InstaFeed}>
                <img src={Insta5} alt="insta5" />
              </div>
              <div className={classes.InstaFeed}>
                <img src={Insta6} alt="insta6" />
              </div>
              <div className={classes.InstaFeed}>
                <img src={Insta7} alt="insta7" />
              </div>
              <div className={classes.InstaFeed}>
                <img src={Insta8} alt="insta8" />
              </div>
              <div className={classes.InstaFeed}>
                <img src={Insta9} alt="insta9" />
              </div>
              <div className={classes.InstaFeed}>
                <img src={Insta10} alt="insta10" />
              </div>
              <div className={classes.InstaFeed}>
                <img src={Insta11} alt="insta11" />
              </div>
              <div className={classes.InstaFeed}>
                <img src={Insta12} alt="insta12" />
              </div>
            </Slider>

            <a href="https://in.pinterest.com/adesign0644/" target="_blank"><svg className="Pin" height="100%" version="1.1" viewBox="0 0 512 512" width="100%"><g><path class="cls-1" d="M511.999,256.002c0,141.373 -114.606,255.979 -255.98,255.979c-141.373,0 -255.979,-114.606 -255.979,-255.979c0,-141.374 114.606,-255.98 255.979,-255.98c141.374,0 255.98,114.606 255.98,255.98Z" /><path class="cls-2" d="M255.998,0.001c-141.384,0 -255.998,114.617 -255.998,255.998c0,108.456 67.475,201.171 162.707,238.471c-2.24,-20.255 -4.261,-51.405 0.889,-73.518c4.65,-19.978 30.018,-127.248 30.018,-127.248c0,0 -7.659,-15.334 -7.659,-38.008c0,-35.596 20.632,-62.171 46.323,-62.171c21.839,0 32.391,16.399 32.391,36.061c0,21.966 -13.984,54.803 -21.203,85.235c-6.03,25.482 12.779,46.261 37.909,46.261c45.503,0 80.477,-47.976 80.477,-117.229c0,-61.293 -44.045,-104.149 -106.932,-104.149c-72.841,0 -115.597,54.634 -115.597,111.095c0,22.004 8.475,45.596 19.052,58.421c2.09,2.535 2.398,4.758 1.776,7.343c-1.945,8.087 -6.262,25.474 -7.111,29.032c-1.117,4.686 -3.711,5.681 -8.561,3.424c-31.974,-14.884 -51.963,-61.627 -51.963,-99.174c0,-80.755 58.672,-154.915 169.148,-154.915c88.806,0 157.821,63.279 157.821,147.85c0,88.229 -55.629,159.232 -132.842,159.232c-25.94,0 -50.328,-13.476 -58.674,-29.394c0,0 -12.838,48.878 -15.95,60.856c-5.782,22.237 -21.382,50.109 -31.818,67.11c23.955,7.417 49.409,11.416 75.797,11.416c141.389,0 256.003,-114.612 256.003,-256.001c0,-141.381 -114.614,-255.998 -256.003,-255.998Z" /></g></svg></a>

            <a href="https://www.instagram.com/anddesignco_/" target="_blank"> <svg className="Instaa" version="1.1" viewBox="0 0 30 30"><g><path class="cls-1" d="M511.999,256.002c0,141.373 -114.606,255.979 -255.98,255.979c-141.373,0 -255.979,-114.606 -255.979,-255.979c0,-141.374 114.606,-255.98 255.979,-255.98c141.374,0 255.98,114.606 255.98,255.98Z" /><path class="cls-2" d="M255.998,0.001c-141.384,0 -255.998,114.617 -255.998,255.998c0,108.456 67.475,201.171 162.707,238.471c-2.24,-20.255 -4.261,-51.405 0.889,-73.518c4.65,-19.978 30.018,-127.248 30.018,-127.248c0,0 -7.659,-15.334 -7.659,-38.008c0,-35.596 20.632,-62.171 46.323,-62.171c21.839,0 32.391,16.399 32.391,36.061c0,21.966 -13.984,54.803 -21.203,85.235c-6.03,25.482 12.779,46.261 37.909,46.261c45.503,0 80.477,-47.976 80.477,-117.229c0,-61.293 -44.045,-104.149 -106.932,-104.149c-72.841,0 -115.597,54.634 -115.597,111.095c0,22.004 8.475,45.596 19.052,58.421c2.09,2.535 2.398,4.758 1.776,7.343c-1.945,8.087 -6.262,25.474 -7.111,29.032c-1.117,4.686 -3.711,5.681 -8.561,3.424c-31.974,-14.884 -51.963,-61.627 -51.963,-99.174c0,-80.755 58.672,-154.915 169.148,-154.915c88.806,0 157.821,63.279 157.821,147.85c0,88.229 -55.629,159.232 -132.842,159.232c-25.94,0 -50.328,-13.476 -58.674,-29.394c0,0 -12.838,48.878 -15.95,60.856c-5.782,22.237 -21.382,50.109 -31.818,67.11c23.955,7.417 49.409,11.416 75.797,11.416c141.389,0 256.003,-114.612 256.003,-256.001c0,-141.381 -114.614,-255.998 -256.003,-255.998Z" /></g><g><circle cx="15" cy="15" r="4" /><path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" /></g></svg></a>

            {/* <div className={classes.ConFir}>
              “Firmitas,
              utilitas,
              venustas.”
            </div>
            <div className={classes.ConX}>
              xxi
            </div> */}
            <a href="https://www.instagram.com/anddesignco_/" target="_balnk" className="CtaBtn mt-4">
              <img src={Watch} alt="WatchBtn" className="Btn" />
              <img src={WatchHover} alt="btnHover" className="BtnHover" />
            </a>
          </Col></Row></Container>
    </div>

  );
};
export default withWidth()(HomeVideo);
