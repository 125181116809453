import React, { useEffect,useState } from "react";
import {
  withWidth
} from "@material-ui/core";
import { styles as useStyles } from "./styles";
import "slick-carousel/slick/slick.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from '../../components/Footer/Footer';
import DetailSlider from '../../components/Detail/DetailSlider/DetailSlider';
import DetailContent from '../../components/Detail/DetailContent/DetailContent';
import DetailMiddleSlider from '../../components/Detail/DetailMiddleSlider/DetailMiddleSlider';
import DetailVideo from '../../components/Detail/DetailVideo/DetailVideo';
import { useParams } from 'react-router-dom';
import WOW from "wowjs";
import { interiors } from "../../utils/data";

const InteriorsDetail = (props) => {
  const classes = useStyles();
  const params = useParams();
  const [detail, setDetail] = useState({});
  const getInteriorDetail=()=>{
     const value =  interiors.find((int)=>int.id == params.id)?.detail;
	 
	  console.log("params.id",params);
     setDetail(value);
  }
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
    getInteriorDetail();
  }, []);

  return (
  
    <div className={classes.main}>
      <Container>
        <Row className="justify-content-center my-2 mb-4">
          <Col md={10}>
            <div className="text-center">
              <h1 className={classes.h1}>{detail.mainHeading}</h1>
              <p className={classes.p}> <strong>{detail.subStrongHeading}</strong> {detail.subHeading}</p>
            </div>
          </Col>
        </Row>
      </Container>
      {detail.mainSliderPictures && detail.mainSliderPictures.length>0 && <DetailSlider images={detail.mainSliderPictures}/>}
      <DetailContent detail={detail}/>
      {detail.middleSliderPictures && detail.middleSliderPictures.length>0 && <Container>
        <Row className="justify-content-center mt-5 pt-md-5">
          <Col md={10}>
            <DetailMiddleSlider images={detail.middleSliderPictures}/>
          </Col>
        </Row>
      </Container>}
      <DetailVideo video={detail.video} id={detail.id} total={12}/>
      <Footer />
    </div>
  );
};

export default withWidth()(InteriorsDetail);
