import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  main: {
    padding: '50px 0',


    [theme.breakpoints.down("xs")]: {
      paddingTop: '20px',
      paddingBottom: '20px'
    },

  },

  FooterLogo: {
    width: '90px',
    display: 'block',
    margin: 'auto'
  },

  FooterLinks: {
    display: 'flex',
    justifyContent: 'center',

  },

  Social: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 'auto',
    marginTop: "5px",
    justifyContent: 'center',
    [theme.breakpoints.down("sm")]: {

      marginBottom: '30px',
    },
    '& svg': {
      width: '40px',
      height: '40px',
      "&:hover": {
        fill: "#97896f",
      },

      [theme.breakpoints.down("sm")]: {
        width: '35px',
        height: '35px',
      },
    },
  },
  SocialLinkk: {
    width: '100%',
    maxWidth: "60px",
    flex: "0 0 60px",
    padding: "10px",

    [theme.breakpoints.down("sm")]: {
      maxWidth: "45px",
      flex: "0 0 45px",
      padding: "5px",
    },

  },

  // facebook: {
  //   width: '35%',
  // },
  // instagram: {
  //   width: '65%',
  // },
  // youtube: {
  //   width: '100%',
  // },
  P: {
    fontSize: '12px',
    textAlign: 'center',
    [theme.breakpoints.down("xs")]: {
      fontSize: '10px',
    },
  }

}));
