
import React, { useEffect } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WhyChooseFull from "../../components/Shared/WhyChooseFull/WhyChooseFull";
import Footer from '../../components/Footer/Footer';





import WOW from "wowjs";

import { styles as useStyles } from './styles';
const Clientele = () => {
  const classes = useStyles();

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();

  }, []);


  const settings = {
    dots: true,
    arrows: false,
    infinite: true,

    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 8000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className={classes.main}>
      <Container className="my-3">
        <Row className="justify-content-center">
          <Col md={10}>
            <div className="text-center">
              <h1 className={classes.h1}>EACH CLIENT ASSOCIATION AND EXPERIENCE ENRICHES OUR ARTISTIC DEXTERITY.</h1>
            </div>
          </Col>
          <Col md={7} className="text-center mt-4">
            <p>
              For us each project association is unique and understanding every client’s vision is cardinal for designing spaces that reflect their character.
            </p>
          </Col>
        </Row>
        <div className="clienteleBg mb-5">
          <Row className="justify-content-start DotsSlider">
            <Col md={10}>
              <Slider {...settings}>
                <div>
                  <Row>
                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>A.R.REHMAN </p>
                      <p className={classes.p}>Music Director</p>
                      {/* <h6 className={classes.H6}>[Studio- Mumbai]</h6> */}
                    </Col>
                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>SHEKHAR SUMAN </p>
                      <p className={classes.p}>Bollywood Personality</p>
                      {/* <h6 className={classes.H6}>[Residence - Mumbai]</h6> */}
                    </Col>
                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>G M RAO  </p>
                      <p className={classes.p}>Group Chairman, GMR</p>
                      {/* <h6 className={classes.H6}>[Residence - Banglore]</h6> */}
                    </Col>
                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>SRINIVAS BOMMIDALA </p>
                      <p className={classes.p}>Business Chairman, GMR, Energy & Intl’ Airports.</p>
                      {/* <h6 className={classes.H6}>[Residence - Banglore]</h6> */}
                    </Col>
                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>PAWAN KALYAN </p>
                      <p className={classes.p}>Telegu Actor</p>
                      {/* <h6 className={classes.H6}>[Residence - Hyderabad]</h6> */}
                    </Col>
                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>J V RANGA RAJU </p>
                      <p className={classes.p}>M.D., Nagarjuna Constructions</p>
                      {/* <h6 className={classes.H6}>[Residence - Banglore]</h6> */}
                    </Col>
                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>UTTAM KOCHAR </p>
                      <p className={classes.p}>Kochar Homes Private Ltd</p>
                      {/* <h6 className={classes.H6}>[Office - Chennai]</h6> */}
                    </Col>
                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>ABHAY SHRISHRIMAL </p>
                      <p className={classes.p}>Chairman, Shasun Chemicals Ltd</p>
                      {/* <h6 className={classes.H6}>[Residence - Chennai]</h6> */}
                    </Col>
                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>TRIVIKRAM SRINIVAS </p>
                      <p className={classes.p}>Telegu Film Director</p>
                      {/* <h6 className={classes.H6}>[Residence - Chennai]</h6> */}
                    </Col>
                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>ANIL LAD </p>
                      <p className={classes.p}>Ex-Member of Parliament</p>
                      {/* <h6 className={classes.H6}>[Residence - Bellari]</h6> */}
                    </Col>
                    {/* <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>SYLVANUS KING PETER </p>
                      <p className={classes.p}>Director, Ocean Lifespace Pvt. Ltd</p>
                       <h6 className={classes.H6}>[Residence - Chennai]</h6> 
                    </Col> */}

                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>Dr. Y. SIVA ARUL DURAI</p>
                      <p className={classes.p}>Chairman, Saravana</p>
                      {/* <h6 className={classes.H6}>[Residence - Chennai]</h6> */}
                    </Col>
                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>G.R. ANAND ANANTHAPADMANABHAN </p>
                      <p className={classes.p}>Chairman, GRT Jewellers</p>
                      {/* <h6 className={classes.H6}>[Residence - Chennai]</h6> */}
                    </Col>

                  </Row>
                </div>

                <div>
                  <Row>

                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>SAILESH KUMAR GOTI </p>
                      <p className={classes.p}>Director, Dharmanandan Diamonds</p>
                      {/* <h6 className={classes.H6}>[Residence - Surat]</h6> */}
                    </Col>
                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>ASHOK POTHY</p>
                      <p className={classes.p}>Director, Pothy Textiles</p>
                      {/* <h6 className={classes.H6}>[Residence - Chennai]</h6> */}
                    </Col>
                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>SHANTI BANARAS  </p>
                      <p className={classes.p}>Mumbai</p>
                      {/* <h6 className={classes.H6}>[Boutique - Mumbai]</h6> */}
                    </Col>
                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>RAVI  K GOVINDAN </p>
                      <p className={classes.p}>Director, Microtex Energy Pvt. Ltd</p>
                      {/* <h6 className={classes.H6}>[Residence - Banglore]</h6> */}
                    </Col>
                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>CHETAN JAIN</p>
                      <p className={classes.p}>CEO, Trichem</p>
                      {/* <h6 className={classes.H6}>[Residence - Mumbai]</h6> */}
                    </Col>
                    {/* <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>SARANG JHAVERI</p>
                      <p className={classes.p}>MD, Jhaveri Jewellers</p>
                       <h6 className={classes.H6}>[Residence - Mumbai]</h6>
                    </Col> */}
                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>SUBHASH JAIN </p>
                      <p className={classes.p}>CEO, Diamond Creation</p>
                      {/* <h6 className={classes.H6}>[Residence - Mumbai]</h6> */}
                    </Col>
                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>AUDIO PEOPLE </p>
                      <p className={classes.p}>Chennai</p>
                      {/* <h6 className={classes.H6}>[Residence - Chennai]</h6> */}
                    </Col>
                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>HARSHVARDHAN BHATIA</p>
                      <p className={classes.p}>Director, HI Diamonds Pvt. Ltd</p>
                      {/* <h6 className={classes.H6}>[Residence - Indore]</h6> */}
                    </Col>
                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>AVS RAJU </p>
                      <p className={classes.p}>Chairman, Nagarjuna Constructions</p>
                      {/* <h6 className={classes.H6}>[Residence - Banglore]</h6> */}
                    </Col>
                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>PRINCESON JOSE </p>
                      <p className={classes.p}>Chairman, Prince Jewellery</p>
                      {/* <h6 className={classes.H6}>[Residence - Chennai]</h6> */}
                    </Col>
                    <Col md={4} sm={6} xs={6} className="my-3 my-md-4">
                      <p className={classes.ClientName}>GMR INFRA LIMITED </p>
                      <p className={classes.p}>Corporate Office</p>
                      {/* <h6 className={classes.H6}>[Office - Hyderabad]</h6> */}
                    </Col>


                  </Row>
                </div>
              </Slider>

            </Col>
          </Row>
        </div>



      </Container>
      <Row className="whyclient">
        <WhyChooseFull />
      </Row>
      <Footer />
    </div >
  );
};

export default Clientele;
