import { makeStyles } from "@material-ui/core/styles";
//import { Autocomplete } from "@material-ui/lab";

export const styles = makeStyles((theme) => ({

  mainMiddle: {
    marginTop: '4.5rem',
    marginBottom: '4rem',
    [theme.breakpoints.down("sm")]: {
      marginTop: '2rem',
      marginBottom: '2rem',
    },
  },

  h1: {
    fontSize: '40px',
    color: '#151212',
    fontFamily: 'Cormorant Infant',
    lineHeight: '0.8',
    fontWeight: '500',
    textAlign: 'center',
    textTransform: 'uppercase',
    [theme.breakpoints.down("md")]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "35px",
      textAlign: 'center',
    },
  },



  p: {
    fontFamily: 'Cormorant Infant',
    lineHeight: '1.2',
    textAlign: 'center',
    [theme.breakpoints.down("xs")]: {
      textAlign: 'center',
    },
  },
  Link: {
    textDecoration: 'underline',
  },

  mY: {
    marginTop: '3rem',
    marginBottom: '3rem',
    [theme.breakpoints.down("xs")]: {
      marginTop: '2rem',
      marginBottom: '2rem',
    }
  },




  AboutOne: {
    borderRadius: '50%',
    [theme.breakpoints.down("xs")]: {
      maxWidth: '80%',
      margin: 'auto',
    },
  },


}));
