import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import HomeImg from "../../assets/images/CIRCLE-BELOW-BANNER.png";
import Logo from "../../assets/images/and-logo.png";
import LogoMobile from "../../assets/images/and-logo.png";
// import AndLogo from "../../assets/images/AND-LOGO-Home.gif";
import AndLogo from "../../assets/images/AndDesignCo.png";



function NavBar() {
  return (
    <div>
      <a href="/contact" className="consultNoHover" ></a>
      <div className="d-flex align-items-center px-3 py-3 px-lg-0 py-lg-0">
        <a href="/" className="d-lg-none NavLogoSide pe-2"><img src={AndLogo} alt="" /></a>
        <a className="NavLogo" href="/">
          <img src={Logo} alt="" />
        </a>




      </div>
      <Navbar expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Brand href="/and"><img src={AndLogo} alt="" /></Navbar.Brand>
            <Nav className="me-auto ms-auto">
              <Nav.Link href="/interiors">interiors</Nav.Link>
              <Nav.Link href="/architecture">architecture</Nav.Link>
              <Nav.Link href="/about">our tale</Nav.Link>
              <Nav.Link href="/press-and-collabs">press  &  collabs</Nav.Link>
              <Nav.Link href="/clientele">Clientele</Nav.Link>
              <Nav.Link href="/contact">contact</Nav.Link>

            </Nav>

            <img src={HomeImg} alt="" className="MobileCircleImg d-lg-none" />
          </Navbar.Collapse>

        </Container>
      </Navbar>
    </div>
  );
}


export default NavBar;
