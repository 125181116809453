import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import Slider1 from "../../../assets/images/slider/AND_00.jpg";
import Slider2 from "../../../assets/images/slider/AND_01.jpg";
import Slider3 from "../../../assets/images/slider/AND_02.jpg";
import Slider4 from "../../../assets/images/slider/AND_03.png";
import Slider5 from "../../../assets/images/slider/AND_04.jpg";
import Slider6 from "../../../assets/images/slider/AND_05.jpg";
import Slider7 from "../../../assets/images/slider/AND_06.jpg";
import Slider8 from "../../../assets/images/slider/AND_07.jpg";
import Slider9 from "../../../assets/images/slider/AND_08.jpg";
import Slider10 from "../../../assets/images/slider/AND_09.jpg";
import Slider11 from "../../../assets/images/slider/AND_10.jpg";
import Slider12 from "../../../assets/images/slider/AND_11.jpg";
import Slider13 from "../../../assets/images/slider/AND_12.jpg";

export default class CenterMode extends Component {
  render() {
    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 800,
      pauseOnHover: false,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
      ],
    };

    return (
      <div className="ArrowXl">
        <Slider {...settings}>
          <div className="feat-img">
            <img src={Slider1} alt="" />
            <div className="Caption">
              Aesthetics chaperoned with functionality
            </div>
          </div>
          <div className="feat-img">
            <img src={Slider2} alt="" />
            <div className="Caption">
              Aesthetics chaperoned with functionality
            </div>
          </div>
          <div className="feat-img">
            <img src={Slider3} alt="" />
            <div className="Caption">
              Aesthetics chaperoned with functionality
            </div>
          </div>
          <div className="feat-img">
            <img src={Slider4} alt="" />
            <div className="Caption">
              Aesthetics chaperoned with functionality
            </div>
          </div>
          <div className="feat-img">
            <img src={Slider5} alt="" />
            <div className="Caption">
              Aesthetics chaperoned with functionality
            </div>
          </div>
          <div className="feat-img">
            <img src={Slider6} alt="" />
            <div className="Caption">
              Aesthetics chaperoned with functionality
            </div>
          </div>
          <div className="feat-img">
            <img src={Slider7} alt="" />
            <div className="Caption">
              Aesthetics chaperoned with functionality
            </div>
          </div>
          <div className="feat-img">
            <img src={Slider8} alt="" />
            <div className="Caption">
              Aesthetics chaperoned with functionality
            </div>
          </div>
          <div className="feat-img">
            <img src={Slider9} alt="" />
            <div className="Caption">
              Aesthetics chaperoned with functionality
            </div>
          </div>
          <div className="feat-img">
            <img src={Slider10} alt="" />
            <div className="Caption">
              Aesthetics chaperoned with functionality
            </div>
          </div>
          <div className="feat-img">
            <img src={Slider11} alt="" />
            <div className="Caption">
              Aesthetics chaperoned with functionality
            </div>
          </div>
          <div className="feat-img">
            <img src={Slider12} alt="" />
            <div className="Caption">
              Aesthetics chaperoned with functionality
            </div>
          </div>
          <div className="feat-img">
            <img src={Slider13} alt="" />
            <div className="Caption">
              Aesthetics chaperoned with functionality
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
