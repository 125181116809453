import React, { Component } from "react";
//import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LOGO1 from "../../../assets/images/about/awards-logos/LOGO_1.png";
import LOGO2 from "../../../assets/images/about/awards-logos/LOGO_2.png";
import LOGO3 from "../../../assets/images/about/awards-logos/LOGO_3.png";
import LOGO4 from "../../../assets/images/about/awards-logos/LOGO_4.png";
import LOGO5 from "../../../assets/images/about/awards-logos/LOGO_5.png";
import LOGO6 from "../../../assets/images/about/awards-logos/LOGO_6.png";
import LOGO7 from "../../../assets/images/about/awards-logos/LOGO_7.png";
import LOGO8 from "../../../assets/images/about/awards-logos/LOGO_8.png";
import LOGO9 from "../../../assets/images/about/awards-logos/LOGO_9.png";
import LOGO10 from "../../../assets/images/about/awards-logos/LOGO_10.png";
import LOGO11 from "../../../assets/images/about/awards-logos/LOGO_11.png";



export default class CenterMode extends Component {
  render() {
    /*const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 500,
      pauseOnHover: false,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
      ],
    }; */

    return (
      <div className="AboutAD">
        <Container>
          <Row className="justify-content-center">

            <Col lg={3} sm={4} xs={12} className="mt-3 mb-3 mb-md-4 mt-md-4">
              <div className="text-center logos">
                <div className="LogIms"><img src={LOGO7} alt="" className="mb-3" /></div>
                <p><strong>FEBRUARY 2023, Frankfurt, GERMANY</strong></p>
                <p>Honoured with prestigious German Design Award Special 2023
                  for the project ‘Sonshine Villa’.</p>
              </div>
            </Col>
            <Col lg={3} sm={4} xs={12} className="mt-3 mb-3 mb-md-4 mt-md-4">
              <div className="text-center logos">
                <div className="LogIms"><img src={LOGO1} alt="" className="mb-3" /></div>
                <p><strong>MARCH 2018, MUMBAI</strong></p>
                <p>Honoured as one of the most influential names in architecture and interior design in the Indian subcontinent, the landmark makers category.</p>
              </div>
            </Col>
            <Col lg={3} sm={4} xs={12} className="mt-3 mb-3 mb-md-4 mt-md-4">
              <div className="text-center logos">
                <div className="LogIms"><img src={LOGO8} alt="" className="mb-3" /></div>
                <p><strong>AUGUST 2019, LONDON</strong></p>
                <p>Finalist under three residential categories for year 2019
                  for the project ‘Sonshine Villa’.</p>
              </div>
            </Col>
            <Col lg={3} sm={4} xs={12} className="mt-3 mb-3 mb-md-4 mt-md-4">
              <div className="text-center logos">
                <div className="LogIms"><img src={LOGO5} alt="" className="mb-3" /></div>
                {/* <p><strong>OCTOBER 2017, VENICE, ITALY</strong></p> */}
                <p>Recognized for high outstanding Architecture</p>
              </div>
            </Col>
            <Col lg={3} sm={4} xs={12} className="mt-3 mb-3 mb-md-4 mt-md-4">
              <div className="text-center logos">
                <div className="LogIms"><img src={LOGO2} alt="" className="mb-3" /></div>
                <p><strong>OCTOBER 2017, VENICE, ITALY</strong></p>
                <p>Recognized for high creativity in adapting traditional
                  design to contemporary living.</p>
              </div>
            </Col>
            <Col lg={3} sm={4} xs={12} className="mt-3 mb-3 mb-md-4 mt-md-4">
              <div className="text-center logos">
                <div className="LogIms"><img src={LOGO3} alt="" className="mb-3" /></div>
                <p><strong>AUGUST 2022, LONDON</strong></p>
                <p>Finalist under residential categories for year 2022 for
                  the project ‘Rustic Reflections’.</p>
              </div>
            </Col>
            <Col lg={3} sm={4} xs={12} className="mt-3 mb-3 mb-md-4 mt-md-4">
              <div className="text-center logos">
                <div className="LogIms"><img src={LOGO4} alt="" className="mb-3" /></div>
                <p><strong>JUNE 2019, BENGALURU</strong></p>
                <p>Recognized for our innovative and substantive approach
                  towards designing & building spaces.</p>
              </div>
            </Col>
            <Col lg={3} sm={4} xs={12} className="mt-3 mb-3 mb-md-4 mt-md-4">
              <div className="text-center logos">
                <div className="LogIms"><img src={LOGO6} alt="" className="mb-3" /></div>
                {/* <p><strong>OCTOBER 2017, VENICE, ITALY</strong></p> */}
                <p>Recognized for high outstanding Architecture</p>
              </div>
            </Col>
            <Col lg={3} sm={4} xs={12} className="mt-3 mb-3 mb-md-4 mt-md-4">
              <div className="text-center logos">
                <div className="LogIms"><img src={LOGO9} alt="" className="mb-3" /></div>
                {/* <p><strong>OCTOBER 2017, VENICE, ITALY</strong></p> */}
                <p>Recognized for high outstanding Architecture</p>
              </div>
            </Col>
            <Col lg={3} sm={4} xs={12} className="mt-3 mb-3 mb-md-4 mt-md-4">
              <div className="text-center logos">
                <div className="LogIms"><img src={LOGO3} alt="" className="mb-3" /></div>
                {/* <p><strong>OCTOBER 2017, VENICE, ITALY</strong></p> */}
                <p>Recognized for high outstanding Architecture</p>
              </div>
            </Col>
            <Col lg={3} sm={4} xs={12} className="mt-3 mb-3 mb-md-4 mt-md-4">
              <div className="text-center logos">
                <div className="LogIms"><img src={LOGO10} alt="" className="mb-3" /></div>
                {/* <p><strong>OCTOBER 2017, VENICE, ITALY</strong></p> */}
                <p>Recognized for high outstanding Architecture</p>
              </div>
            </Col>
            <Col lg={3} sm={4} xs={12} className="mt-3 mb-3 mb-md-4 mt-md-4">
              <div className="text-center logos">
                <div className="LogIms"><img src={LOGO11} alt="" className="mb-3" /></div>
                {/* <p><strong>OCTOBER 2017, VENICE, ITALY</strong></p> */}
                <p>Recognized for high outstanding Architecture</p>
              </div>
            </Col>


          </Row>
        </Container>
      </div>
    );
  }
}
