import { makeStyles } from "@material-ui/core/styles";
//import { Autocomplete } from "@material-ui/lab";

export const styles = makeStyles((theme) => ({

  root: {
    backgroundColor: '#000',
    padding: '20px 0',
    marginBottom: '2.2rem',
    [theme.breakpoints.down("sm")]: {
      marginTop: '0rem',
      marginBottom: '5rem',
      paddingTop: ' 30px',
      paddingBottom: ' 2px',
    },
  },

  h1: {
    fontSize: '40px',
    color: '#ffffff',
    fontFamily: 'Cormorant Infant',
    lineHeight: '0.8',
    fontWeight: '500',

    textTransform: 'uppercase',
    [theme.breakpoints.down("md")]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "35px",
    },
  },



  p: {
    color: '#ffffff',
    fontSize: '15px',
    textTransform: 'uppercase',
    marginBottom: '0',
    [theme.breakpoints.down("xs")]: {

    },
  },
  Link: {
    textDecoration: 'underline',
  },

  mY: {
    marginTop: '3rem',
    marginBottom: '3rem',
    [theme.breakpoints.down("xs")]: {
      marginTop: '2rem',
      marginBottom: '2rem',
    }
  },




  AboutOne: {
    borderRadius: '50%',
    [theme.breakpoints.down("xs")]: {
      maxWidth: '80%',
      margin: 'auto',
    },
  },


}));
