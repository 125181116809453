import { makeStyles } from "@material-ui/core/styles";
//import { Autocomplete } from "@material-ui/lab";

export const styles = makeStyles((theme) => ({
    root: {
        marginTop: '40px',
        marginBottom: '40px',
    },
    h1: {
        fontSize: "35px",
        fontFamily: 'Cormorant Infant',
        lineHeight: '0.8',
        [theme.breakpoints.down("md")]: {
            fontSize: "35px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "30px",
        },
    },
    p: {
        fontSize: "16px",
        fontFamily: 'Cormorant Infant',
        lineHeight: '1.3',
        fontWeight: "500",
        maxWidth: '765px',
        margin: 'auto',
        marginTop: '10px',
        textAlign: 'justify',
        textAlignLast: 'center'
    },
    p2: {
        fontSize: "16px",
        fontFamily: 'Cormorant Infant',
        lineHeight: '1.3',
        fontWeight: "500",
        margin: 'auto',
        marginTop: '10px',
    },

    Img: {
        width: '105px',
        margin: 'auto',
        display: 'block',
        [theme.breakpoints.down("md")]: {
            width: '100px',
        },
        [theme.breakpoints.down("xs")]: {
            width: '80px',
        },
    },
    ImgYear: {
        width: '115px',
        display: 'block',
        margin: 'auto',
        transition: 'all ease 0.2s',
        "&:hover": {
            transform: 'translateY(-8px)',
        }
    },
    ImgVitr: {
        width: '105px',
        display: 'block',
        margin: 'auto',
        transition: 'all ease 0.2s',
        "&:hover": {
            transform: 'translateY(-8px)',
        }
    },
    ImgAD: {
        width: '100%',
        display: 'block',
        transition: 'all ease 0.2s',
        "&:hover": {
            transform: 'translateY(-8px)',
        }
    },
    Icon: {
        width: '300px',
        overflow: 'hidden',
        "&:hover": {
            transition: 'all .3s ease',
            width: '330px'
        }
    }
}));
