import { makeStyles } from "@material-ui/core/styles";

import Instagram from "../../../assets/images/InstaFeed.jpg";
//import { Autocomplete } from "@material-ui/lab";

export const styles = makeStyles((theme) => ({

  root: {
    backgroundColor: '#000',
    padding: "60px 0 0",
    // backgroundImage: `url(${Instagram})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: "relative",
    marginTop: '20px',
    
  },




  h2: {
    fontSize: "36px",
    textTransform: "uppercase",
    color: "#fff",
    fontWeight: "400",
    letterSpacing: '1.2px',
    fontFamily: "Cormorant Infant",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      letterSpacing: '2px',
      textAlign: 'center',
    },
  },


  p: {
    color: "#fff",
    fontSize: "12px",
    lineHeight: "1",
    textTransform: "uppercase",
    marginTop: "15px",
    letterSpacing: "0.6px",

   

    "& strong": {
      fontWeight: '600',
    },

  },

  HomeImg: {
    position: "absolute",
    right: "0px",
    left: "0",
    margin: "auto",
    top: "-85px",
    width: "170px",
    [theme.breakpoints.down("sm")]: {
      width: "150px",
      top: "-80px",
    },
    
  },
  InstaFeed: {
    maxWidth: '70vw',
    display: 'block !important',
    margin: 'auto',
    "& img": {
      width: '100%'
    }
  },
  ConFir: {
    position: 'absolute',
    fontFamily: "Cormorant Infant",
    color: "#fff",
    lineHeight: "0.9",
    textTransform: "uppercase",
    right: '0px;',
    width: "100px",
    fontSize: "13px",
    transform: "rotate(90deg)",
    bottom: "190px",
    [theme.breakpoints.down("md")]: {
      display: 'none',
    },
  },

  ConX: {
    position: 'absolute',
    fontFamily: "Cormorant Infant",
    color: "#fff",
    lineHeight: "0.9",
    letterSpacing: "18px",
    textTransform: "uppercase",
    right: '-10px;',
    width: "100px",
    fontSize: "12px",
    transform: "rotate(90deg)",
    bottom: "50px",
   
  },
  pin: {
    borderRadius: '40px',
    width: '60px',
    position: 'absolute',
    right: '20px',
    marginTop: '-248px'
  }

}));
