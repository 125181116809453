import React, { useEffect } from "react";
import {
  withWidth
} from "@material-ui/core";
import { styles as useStyles } from "./styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from '../../components/Footer/Footer';
import PressContent from '../../components/Press/PressContent/PressContent';
import PressContact from '../../components/Press/PressContact/PressContact';
import Press1 from "../../assets/images/press/press1.jpg";
import Press2 from "../../assets/images/press/press2.jpg";
import Press3 from "../../assets/images/press/press3.jpg";
import Press4 from "../../assets/images/press/press4.jpg";
import Press5 from "../../assets/images/press/press5.jpg";
import Press6 from "../../assets/images/press/press6.jpg";
import Press7 from "../../assets/images/press/press7.jpg";
import Press8 from "../../assets/images/press/press8.jpg";
import Press9 from "../../assets/images/press/press9.jpg";
import Press10 from "../../assets/images/press/press10.jpg";
import Press11 from "../../assets/images/press/press11.jpg";
import Press12 from "../../assets/images/press/press12.jpg";
import Press13 from "../../assets/images/press/press13.jpg";
import Press14 from "../../assets/images/press/press14.jpg";
import Press15 from "../../assets/images/press/press15.jpg";
import HPress1 from "../../assets/images/press/hover/press1.jpg";
import HPress2 from "../../assets/images/press/hover/press2.jpg";
import HPress3 from "../../assets/images/press/hover/press3.jpg";
import HPress4 from "../../assets/images/press/hover/press4.jpg";
import HPress6 from "../../assets/images/press/hover/press6.jpg";
import HPress7 from "../../assets/images/press/hover/press7.png";
import HPress8 from "../../assets/images/press/hover/press8.jpg";
import HPress9 from "../../assets/images/press/hover/press9.png";
import HPress10 from "../../assets/images/press/hover/press10.jpg";
import HPress11 from "../../assets/images/press/hover/press11.png";
import HPress12 from "../../assets/images/press/hover/press12.png";
import HPress13 from "../../assets/images/press/hover/press13.jpg";
import HPress14 from "../../assets/images/press/hover/press14.jpg";
import HPress15 from "../../assets/images/press/hover/press15.jpg";

import Press16 from "../../assets/images/press/press16.jpg";
import HPress16 from "../../assets/images/press/hover/press16.jpg";

import Press17 from "../../assets/images/press/press17.jpg";
import HPress17 from "../../assets/images/press/hover/press17.jpg";


import WOW from "wowjs";
const Press = (props) => {
  const classes = useStyles();

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();

  }, []);

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    speed: 900,
    draggable: false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          arrows: true,
          infinite: true,
          draggable: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: true,
          infinite: true,
          draggable: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: true,
          infinite: true,
          draggable: true,
        },
      },
    ],
  };

  return (
    <div className={classes.root}>
      <Container>
        <Row className="justify-content-center">
          <Col xs={10} md={11}>
            <Row className="justify-content-center mt-4 mt-md-5">
              <Col md={4} className="text-center">
                <div className="pressHover">
                  <img className="first" src={Press17} alt="" />
                  <img className="second" src={HPress17} alt="" />
                </div>
                <div className={classes.PressMedia}>
                  <div className={classes.Margin}>
                    <p className={classes.p}>LIVING ETC, FEBRUARY 2023</p>
                    <p className={classes.H6}>Featured project: Bull of Heaven, Chennai</p>
                  </div>
                </div>
				</Col>
				<Col md={4} className="text-center">
                <div className="pressHover">
                  <img className="first" src={Press16} alt="" />
                  <img className="second" src={HPress16} alt="" />
                </div>
                <div className={classes.PressMedia}>
                  <div className={classes.Margin}>
                    <p className={classes.p}>ARCHITECT AND INTERIORS INDIA, MARCH 2023</p>
                    <p className={classes.H6}>Featured Cover Story</p>
                  </div>
                </div>
				</Col>
				
              <Col md={4} className="text-center">
                <div className={classes.PressMedia}>
                  <div className="pressHover">
                    <img className="first" src={Press2} alt="" />
                    <img className="second" src={HPress2} alt="" />
                  </div>
                  <div className={classes.Margin}>
                    <p className={classes.p}>architectural digest, MARCH 2018</p>
                    <p className={classes.H6}>Featured as AD 100 Most influential names in architecture and interior design in the Indian subcontinent.</p>
                  </div>
                </div>
              </Col>
			  
            </Row>
          </Col>
        </Row>
      </Container>
      <PressContact />
      <PressContent />
      <Container className="PressSlider">
        <Row className="justify-content-center">
          <Col xs={10} md={11} className="mt-1 pt-2 pt-sm-4">
            <Slider {...settings}>
              <div>
				 <div className="pressHover">
                  <img className="first" src={Press1} alt="" />
                  <img className="second" src={HPress1} alt="" />
                </div>
                <div className={classes.PressMedia}>
                  <div className={classes.Margin}>
                    <p className={classes.p}>elle decor , january  2021</p>
                    <p className={classes.H6}>Featured project: Rustic Reflections, Chennai</p>
                  </div>
                </div>
				<div className={classes.PressMedia}>
                  <div className="pressHover">
                    <img className="first" src={Press3} alt="" />
                    <img className="second" src={HPress3} alt="" />
                  </div>
                  <div className={classes.Margin}>
                    <p className={classes.p}>LIVNG ETC, DECEMBER 2020</p>
                    <p className={classes.H6}>Featured project: Sonshine Villa, Chennai </p>
                  </div>
                </div>
                <div className={classes.PressMedia}>
                  <div className="pressHover">
                    <img className="first" src={Press4} alt="" />
                    <img className="second" src={HPress4} alt="" />
                  </div>
                  <div className={classes.Margin}>
                    <p className={classes.p}>LIVING ETC , FEBRUARY  2018</p>
                    <p className={classes.H6}>Featured article on special mention at the iDOGI awards inVenice, Italy, for adapting traditional design to contemprory living. </p>
                  </div>
                </div>
                <div className={classes.PressMedia}>
                  <div className="pressHover">
                    <img className="first" src={Press7} alt="" />
                    <img className="second" src={HPress7} alt="" />
                  </div>
                  <div className={classes.Margin}>
                    <p className={classes.p}>INDIA MONDO, 2016</p>
                    <p className={classes.H6}>Featured Section: Importance of lighting in the work of design practice</p>
                  </div>
                </div>
                <div className={classes.PressMedia}>
                  <div className="pressHover">
                    <img className="first" src={Press10} alt="" />
                    <img className="second" src={HPress10} alt="" />
                  </div>
                  <div className={classes.Margin}>
                    <p className={classes.p}>society interiors, march  2017</p>
                    <p className={classes.H6}>Featured Interview  </p>
                  </div>
                </div>
                
              </div>
              <div>
			  <div className={classes.PressMedia}>
                  <div className="pressHover">
                    <img className="first" src={Press13} alt="" />
                    <img className="second" src={HPress13} alt="" />
                  </div>
                  <div className={classes.Margin}>
                    <p className={classes.p}>SOCIETY INTERIORS, MAY 2003</p>
                    <p className={classes.H6}>Featured Dialogue with Rupesh & Archana Baid on significance of colours in interiors </p>
                  </div>
                </div>
                <div className={classes.PressMedia}>
                  <img className={classes.Main} src={Press5} alt="" />
                  <div className={classes.Margin}>
                    <p className={classes.p}>DESIGN MATRIX, JUNE  2012</p>
                    <p className={classes.H6}>Featured Cover</p>
                  </div>
                </div>
                <div className={classes.PressMedia}>
                  <div className="pressHover">
                    <img className="first" src={Press8} alt="" />
                    <img className="second" src={HPress8} alt="" />
                  </div>
                  <div className={classes.Margin}>
                    <p className={classes.p}>living etc, january  2020</p>
                    <p className={classes.H6}>'Featured special project on artwork  and AI innovative technologies</p>
                  </div>
                </div>
                <div className={classes.PressMedia}>
                  <div className="pressHover">
                    <img className="first" src={Press11} alt="" />
                    <img className="second" src={HPress11} alt="" />
                  </div>
                  <div className={classes.Margin}>
                    <p className={classes.p}>THE DESIGN SOURCE, JUNE-JULY 2016</p>
                    <p className={classes.H6}>Featured Article on design philosophy of AND DESIGN CO </p>
                  </div>
                </div>
                <div className={classes.PressMedia}>
                  <div className="pressHover">
                    <img className="first" src={Press14} alt="" />
                    <img className="second" src={HPress14} alt="" />
                  </div>
                  <div className={classes.Margin}>
                    <p className={classes.p}>SOCIETY INTERIORS, NOVEMBER 2005</p>
                    <p className={classes.H6}>Featured Work: Sakariya Residence, Chennai  </p>
                  </div>
                </div>
              </div>
              <div>
                <div className={classes.PressMedia}>
                  <div className="pressHover">
                    <img className="first" src={Press6} alt="" />
                    <img className="second" src={HPress6} alt="" />
                  </div>
                  <div className={classes.Margin}>
                    <p className={classes.p}>LIVING ETC, MARCH 2019</p>
                    <p className={classes.H6}>Featured Project: The Chapters</p>
                  </div>
                </div>
                <div className={classes.PressMedia}>
                  <div className="pressHover">
                    <img className="first" src={Press9} alt="" />
                    <img className="second" src={HPress9} alt="" />
                  </div>
                  <div className={classes.Margin}>
                    <p className={classes.p}>THE DESIGN SOURCE, JULY 2011</p>
                    <p className={classes.H6}>Featured Dialogue with Rupesh Baid on prestigious iSaloni event</p>
                  </div>
                </div>
                <div className={classes.PressMedia}>
                  <div className="pressHover">
                    <img className="first" src={Press12} alt="" />
                    <img className="second" src={HPress12} alt="" />
                  </div>
                  <div className={classes.Margin}>
                    <p className={classes.p}>SOCIETY INTERIORS, APRIL 2012</p>
                    <p className={classes.H6}>Featured: Outstanding contribution in the field of interior design </p>
                  </div>
                </div>
                <div className={classes.PressMedia}>
                  <div className="pressHover">
                    <img className="first" src={Press15} alt="" />
                    <img className="second" src={HPress15} alt="" />
                  </div>
                  <div className={classes.Margin}>
                    <p className={classes.p}>INSIDE OUTSIDE, SEPTEMBER 2000</p>
                    <p className={classes.H6}>Featured Work: Shekhar Suman’s Residence, Mumbai  </p>
                  </div>
                </div>
              </div>
            </Slider>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};


export default withWidth()(Press);
