import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#99896c',
      fontColor: '#FFFFFF',
      gradientColor: 'rgba(250, 133, 133, 0.75)',
      aboutGradient1: '#ffa2b7',
      aboutGradient2: '#ffc59b',
      headerGradient: 'rgba(254,154,123,0.4)',
      coursesGradient1: 'rgba(255, 211, 132, 0.9)',
      coursesGradient2: 'rgba(255, 155, 155, 0.89)',
      courseHeading: '#444e72',
    },
    secondary: {
      main: '#FEBC81',
      headFontColor: '#3d445d',
      paraFontColor: '#2f2c2b',
    },
    other: {
      white: '#FFFFFF',
      offWhite: '#f6f9ff',
      blue: '#32327c',
      black: '#000000',
      shadow: 'rgba(3, 3, 87, 0.31)',
      darkGrey: '#394962',
      orange: '#fd9964',
      fadeOrange: '#fdbf9e',
      fadePink: '#ff8686',
      lightGrey: '#5f5f5f',
      skin: '#fff5ed',
      opaqueSky: 'rgba(172,239,232, 0.9)',
      peacockBlue: '#7c94ff',
      grey: '#8d8d8d',
      lighterGrey: '#707070',
      lightestGrey: '#EDEDED',
    },
  },
});
