import React, { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Hidden } from "@material-ui/core";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Footer from '../../components/Footer/Footer';
import ContactImg from "../../assets/images/contact.png";
import ContactImgMob from "../../assets/images/constatMob.jpg";
import Vitruman from "../../assets/images/VITRUVIAN-MAN.png";
import InstaFeeds from "../../components/Shared/InstaFeeds/InstaFeeds";

import Submit from "../../assets/images/submit.png";
import SubmitHover from "../../assets/images/submitHover.png";

import WOW from "wowjs";
import { styles as useStyles } from './styles';

const Contact = () => {
  const classes = useStyles();

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();

  }, []);

  return (
    <div className={classes.main}>
      <Container className="mt-3 mb-0">
        <Row className="align-items-start">
          <Col lg={5} >
            <div className="wow fadeInUp mt-5 px-md-2">
              <img src={ContactImg} alt="" className={classes.desk} />
              <img src={ContactImgMob} alt="" className={classes.mob} />
            </div>
          </Col>
          <Col lg={7} className="ps-sm-5 pe-md-3 px-0 pb-sm-0 pt-sm-4">

            <div className="ComBlack pb-0">
              <h1 className={classes.h2}>enquire now</h1>

              <Form className="mt-4">
                <div>
                  <input type="text" name="name" className="form-control" placeholder="Name" />
                </div>
                <div>
                  <input type="email" name="name" className="form-control" placeholder="Email ID *" />
                </div>
                <div>
                  <input type="text" name="name" className="form-control" placeholder="Phone *" />
                </div>
                <div>
                  <select className="form-control">
                    <option>Project</option>
                    <option>Press</option>
                    <option>Work with us</option>
                  </select>
                </div>
                <div>
                  <textarea rows="5" className="form-control" placeholder="Message"></textarea>
                </div>
                <div className="text-end mt-2">
                  <a href="javascript:void" className="CtaBtn ms-auto me-0">
                    <img class="Btn" src={Submit} alt="" />
                    <img class="BtnHover" src={SubmitHover} alt="" />
                  </a>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <Hidden xsDown>
        <div className="Height10"></div>
      </Hidden>
      <Container className="mt-0">
        <Row className="align-items-center">
          <Col lg={12} className="ps-sm-5 pe-md-3 px-0 pb-sm-4 pt-sm-4">
            <div className={classes.ConMobile}>


              <div className="d-md-flex">
                <div className="col-lg">
                  <p className={classes.p}><strong> for all enquiries, mail us at</strong> </p>
                  <p className={classes.Mail}><a className={classes.anch} href="mailto:design@anddesignco.com">  design@anddesignco.com</a> <br />
                    <a className={classes.anch} href="mailto:anddesign@gmail.com">anddesign@gmail.com </a> </p>
                </div>
                <div className="col-lg paddingTopSecond">
                  <p className={classes.p} >
                    <strong> FOR PRESS QUERY,</strong> <br />
                    call us at <strong><a className={classes.anch} href="tel:+91-8591903086">+91-8591903086</a></strong>
                  </p>

                </div>
                <div className="col-lg paddingTop">
                  <p className={classes.p}>
                    <strong> FOR project QUERY, </strong>  <br />
                    call us at <strong><a className={classes.anch} href="tel:+91-8591903086">+91-8591903086</a></strong>
                  </p>
                </div>
                <div className="col-lg paddingTopSecond">
                  <p className={classes.p}>
                    To work with us, email us your cv at

                    <strong className={classes.Link}> <a className={classes.anch} href="mailto:admin@anddesignco.com">admin@anddesignco.com</a>
                    </strong> <br />
                  </p>

                  <p className={classes.p}>  <br />
                    Keep an eye on our openings over our <strong className={classes.Link}>
                      <a className={classes.anch} href="https://www.linkedin.com/company/anddesignco/" target="_blank" rel="noreferrer">linkedin account</a></strong></p>


                </div>
              </div>




            </div>
          </Col>
        </Row>

      </Container>

      <InstaFeeds />
      <Footer />
    </div >
  );
};

export default Contact;
