import React, { useEffect } from "react";
import {
  withWidth
} from "@material-ui/core";
import { styles as useStyles } from "./styles";
import "slick-carousel/slick/slick.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from '../../components/Footer/Footer';
import DetailSlider from '../../components/Architecture/DetailSlider/DetailSlider';
import DetailContent from '../../components/Architecture/DetailContent/DetailContent';
import DetailMiddleSlider from '../../components/Architecture/DetailMiddleSlider/DetailMiddleSlider';
import DetailVideo from '../../components/Architecture/DetailVideo/DetailVideo';
import WOW from "wowjs";
const Architecture = (props) => {
  const classes = useStyles();

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <div className={classes.main}>
      <DetailSlider />
      <DetailContent />
      <Container>
        <Row className="justify-content-center mt-5 pt-md-5">
          <Col md={10}>
            <DetailMiddleSlider />
          </Col>
        </Row>
      </Container>
      <DetailVideo />
      <Footer />
    </div>
  );
};

export default withWidth()(Architecture);
