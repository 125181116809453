import React, { useEffect,useRef } from "react";
import {
  withWidth
} from "@material-ui/core";
import { styles as useStyles } from "./styles";
import "slick-carousel/slick/slick.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from '../../components/Footer/Footer';
import WOW from "wowjs";
import { interiors, watch, watchHover } from "../../utils/data";
const Interiors = (props) => {
  const classes = useStyles();
  const videoEl = useRef(null);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <div className={classes.main}>
      <Container>
        <Row className="justify-content-center my-2">
          <Col md={10}>
            <div className="text-center">
              <h1 className={classes.h1}>timeless interiors & <br className="d-none d-md-block" />
                Pure luxury</h1>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} md={10}>
            <Row className="justify-content-center mt-4 mt-md-5">
            {interiors.map((interior)=>{
            return  interior.isCircle ?
              (interior && <Col xs={6} md={6} className="text-center mb-4 mb-md-5" key={`interior-${interior.id}`}>
                <div className={classes.PressMedia}>
                  <div className="InteriorMedia wow fadeInDown">
                    {interior.video === 1 ? (
                      <video
                        playsInline
                        autoPlay
                        loop
                        muted
                        src={interior.picture}
                        ref={videoEl}
                        className={classes.interiorVideo}
                        />
                      ) : (
                      <img src={interior.picture} alt="" />
                      )}
                  </div>
                  <div className={classes.Margin}>
                    <p className={classes.H6}></p>
                  </div>
                </div>
              </Col>)
              :
              ( interior && <Col xs={6} md={6} className="text-center mb-4 mb-md-5" key={`interior-${interior.id}`}>
                <div className={classes.PressMedia}>
                  <div className="InteriorMedia">
                    <img src={interior.picture} alt={`interior-${interior.id}`} />
                    <div className="TakeBtn">
                      <a href={interior.url} className="CtaBtn">
                        <img src={watch} alt="watch-link" className="Btn" />
                        <img src={watchHover} alt="watch-link-hover" className="BtnHover" />
                      </a>
                    </div>
                  </div>
                  <div className={classes.Margin}>
                    <p className={classes.H6}>{interior.text}</p>
                  </div>
                </div>
              </Col>)
            })}
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default withWidth()(Interiors);
