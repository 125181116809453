import {
  withWidth
} from "@material-ui/core";
import { styles as useStyles } from "./styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import PressOne from "../../../assets/images/press/PressOne.jpg";
import PressTwo from "../../../assets/images/collab2.gif";



const PressContent = (props) => {
  const classes = useStyles();

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    fade: true,
    speed: 900,
    draggable: false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: true,
        },
      },
    ],
  };

  return (

    <div className={classes.root}>


      <Container className={classes.mainMiddle}>
        <div className="PressMediaSlider">
          <Slider {...settings}>
            <div>
              <Row className=" justify-content-center">
                <Col xs={11}>

                  <Row>
                    <Col lg={4} className="text-center order-lg-2">
                      <div className="d-lg-none text-center mb-4 mb-lg-0">
                        <h1 className={classes.h1}>
                          My Vitruvian Man
                          in collaboration with Stonex India</h1>
                      </div>
                      <img src={PressOne} alt="" className={classes.AboutOne} />

                    </Col>
                    <Col lg={8} className="order-md-1 pb-50">
                      <div className={classes.ConMobile}>
                        <div className="d-none d-lg-block">
                          <h1 className={classes.h1}>
                            My Vitruvian Man
                            in collaboration with Stonex India</h1>
                        </div>

                        <div className={classes.mY}></div>

                        <p className={classes.p}>
                          My inspiration is Leonardo Da Vinci, artist and musician and philosopher and inventor and architect. As we all know his ideas, thoughts and creations have shaped the future in the most unusual ways. I have always been mesmerised by Da Vinci’s Vitruvian Man and decided to honour the revolutionary illustration when an opportunity presented itself in form of Ace Luxe Design Show 2017.
                        </p>
                        <p className={classes.p}>
                          Our team created a life-size sculpture with marble. To showcase the union of perfect Architecture and unconventional Art, a combination of symmetry and asymmetry. My Vitruvian Man is a blending contrast of both. It’s a sculpture that motivates us to be more than what we can be, always growing, always learning.
                        </p>
                      </div>
                    </Col>
                  </Row>

                </Col>
              </Row>
            </div>
            <div>
              <Row className=" justify-content-center">
                <Col xs={11}>

                  <Row>
                    <Col lg={4} className="text-center order-md-2">
                      <div className="d-lg-none text-center mb-4 mb-lg-0">
                        <h1 className={classes.h1}>
                          OMBRE SULLA STRADA RUG IN COLLABORATION WITH HANDS.</h1>
                      </div>
                      <img src={PressTwo} alt="" className={classes.AboutOne} />

                    </Col>
                    <Col lg={8} className="order-lg-1 pb-50">
                      <div className={classes.ConMobile}>
                        <div className="d-none d-lg-block">
                          <h1 className={classes.h1}>
                            OMBRE SULLA STRADA RUG IN COLLABORATION WITH HANDS.</h1>
                        </div>

                        <div className={classes.mY}></div>

                        <p className={classes.p}>
                          Inspiration often finds you in the most unlikely places. This time it lay right beneath our feet, in Milan – a manhole lid. An innocuous utility object made of cast iron and concrete. Our eyes celebrated the quirky shadow play created by the sun, metallic bead-heads and the vertical jaali. The colours were understated luxury - the earth, cast iron and concrete greys and dust shimmering like gold.</p>

						<p className={classes.p}>To bring this design to life, we collaborated with HANDS and their years of expertise in creating handcrafted carpets. Together our teams spent hours researching textures and working with the highly skilled craftsmen. The result? A hand woven rug, we call – Ombre Sulla Strada, shadows on the street.
                        </p>

                      </div>
                    </Col>
                  </Row>

                </Col>
              </Row>
            </div>
          </Slider>
        </div>




      </Container>

    </div>

  );
};
export default withWidth()(PressContent);
