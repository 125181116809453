
import React, { Component } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import "slick-carousel/slick/slick.css";
import HoverVideoPlayer from 'react-hover-video-player';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Video1 from "../../../assets/video/testimonial1.mp4";
import VideoMobile1 from "../../../assets/video/mobile/video1.mp4";
import Video2 from "../../../assets/video/testimonial2.mp4";
import VideoMobile2 from "../../../assets/video/mobile/video2.mp4";
import Video3 from "../../../assets/video/testimonial3.mp4";
import VideoMobile3 from "../../../assets/video/mobile/video3.mp4";
import PlayButton from "../../../assets/images/PLAY.jpg";
import PauseButton from "../../../assets/images/pause.jpg";

class WhyChooseFull extends Component {

  constructor() {
    super();
    this.state = {
      videoPlay: true,
      videoNumber: 1
    }
    this.vidRef1 = React.createRef();
    this.vidRef2 = React.createRef();
    this.vidRef3 = React.createRef();
  }
  componentDidMount = () => {
    this.playVideo({ pre: true, refNumber: 1 });
    this.vidRef1?.current?.pause();
    this.vidRef2?.current?.pause();
    this.vidRef3?.current?.pause();
  };

  componentWillUnmount = () => {
    this.pauseVideo();
  };


  playVideo = ({ pre, refNumber }) => {
    pre ? this.setState({ videoPlay: true, videoNumber: 1 }) : this.setState({ videoPlay: false, videoNumber: 1 });
    if (refNumber === 1) {
      this.vidRef1.current.play();
    } else if (refNumber === 2) {
      this.setState({ videoNumber: 2, videoPlay: false })
      this.vidRef2.current.play();
    } else {
      this.setState({ videoNumber: 3, videoPlay: false })
      this.vidRef3.current.play();
    }
  };

  pauseVideo = ({ refNumber }) => {
    if (refNumber === 1) {
      this.setState({ videoNumber: 1, videoPlay: true })
      this.vidRef1.current.pause();
    } else if (refNumber === 2) {
      this.setState({ videoNumber: 2, videoPlay: true })
      this.vidRef2.current.pause();
    } else {
      this.setState({ videoNumber: 3, videoPlay: true })
      this.vidRef3.current.pause();
    }
  };
  tabClick = (eventKey) => {
    const tabKeys = {
      VIDEO1: 'video1',
      VIDEO2: 'video2',
      VIDEO3: 'video3'
    };
    if (eventKey === tabKeys.VIDEO1) {
      this.vidRef2.current.pause();
      this.vidRef3.current.pause();
      this.setState({ videoNumber: 1, videoPlay: true });
    }
    if (eventKey === tabKeys.VIDEO2) {
      this.vidRef1.current.pause();
      this.vidRef3.current.pause();
      this.setState({ videoNumber: 2, videoPlay: true });
    }
    if (eventKey === tabKeys.VIDEO3) {
      this.vidRef1.current.pause();
      this.vidRef2.current.pause();
      this.setState({ videoNumber: 3, videoPlay: true });
    }
  }
  render() {
    return (
      <div className="WhyChooseFull" id="whyfull">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} className="text-center">
              <h2 className="whyChoose"> why choose and? </h2>
            </Col>
            <Col xs={12} className="text-center d-none d-md-flex justify-content-center">
              <h2 className="headingXl colorWhite"> why choose and? </h2>
            </Col>
            <Col md={9} className="mt-0 mt-md-5 px-0 px-md-3 TabsVideos">

              <Tabs
                defaultActiveKey="video1"
                id="uncontrolled-tab-example"
                className="mb-3"
                onSelect={(eventKey, obj) => this.tabClick(eventKey)}
              >
                <Tab eventKey="video1" title="Home">
                  <div className="WhyFulls">
                    <div className="VideoRelative">
                      <div className="d-none d-md-block">

                        <video ref={this.vidRef1} src={Video1} type="video/mp4" />
                        <div className="PlayPause playPauseTopLeft">
                          {this.state.videoPlay ?
                            <button onClick={() => this.playVideo({ refNumber: 1 })}>
                              <img src={PlayButton} alt="Play" />
                            </button>
                            :
                            <button className="pause" onClick={() => this.pauseVideo({ refNumber: 1 })}>
                              <img src={PauseButton} alt="Pause" />
                            </button>
                          }
                        </div>

                      </div>
                      <div className="d-md-none">
                        <HoverVideoPlayer
                          className="Videoobject"
                          videoSrc={VideoMobile1}
                          muted={false}
                          loadingOverlay={
                            <div className="loading-overlay">
                              <div className="loading-spinner" />
                            </div>
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-10">
                        <p className="text-md-start"><strong>PAULO BLEVE </strong> IFDM CHIEF EDITOR & FOUNDER</p>
                      </div>
                    </div>

                  </div>
                </Tab>
                <Tab eventKey="video2" title="Profile">
                  <div className="WhyFulls">
                    <div className="VideoRelative">
                      <div className="d-none d-md-block">
                        <video ref={this.vidRef2} src={Video2} type="video/mp4" />
                        <div className="PlayPause playPauseTopLeft">
                          {this.state.videoPlay ?
                            <button onClick={() => this.playVideo({ refNumber: 2 })}>
                              <img src={PlayButton} alt="Play" />
                            </button>
                            :
                            <button className="pause" onClick={() => this.pauseVideo({ refNumber: 2 })}>
                              <img src={PauseButton} alt="Pause" />
                            </button>
                          }
                        </div>
                      </div>
                      <div className="d-md-none">
                        <HoverVideoPlayer
                          className="Videoobject"
                          videoSrc={VideoMobile2}
                          muted={false}
                          loadingOverlay={
                            <div className="loading-overlay">
                              <div className="loading-spinner" />
                            </div>
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-10">
                        <p className="text-md-start"><strong>TRIVIKRAM SRINIVAS </strong> FILM DIRECTOR and Dialogue  Writer</p>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="video3" title="Contact">
                  <div className="WhyFulls">
                    <div className="VideoRelative">
                      <div className="d-none d-md-block">
                        <video ref={this.vidRef3} src={Video3} type="video/mp4" />
                        <div className="PlayPause playPauseTopLeft">
                          {this.state.videoPlay ?
                            <button onClick={() => this.playVideo({ refNumber: 3 })}>
                              <img src={PlayButton} alt="Play" />
                            </button>
                            :
                            <button className="pause" onClick={() => this.pauseVideo({ refNumber: 3 })}>
                              <img src={PauseButton} alt="Pause" />
                            </button>
                          }
                        </div>
                      </div>
                      <div className="d-md-none">
                        <HoverVideoPlayer
                          muted={false}
                          className="Videoobject"
                          videoSrc={VideoMobile3}
                          loadingOverlay={
                            <div className="loading-overlay">
                              <div className="loading-spinner" />
                            </div>
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-10">
                        <p className="text-md-start"><strong>
                          ARCHITECT CRISTINA MAGGIOLO</strong> BELCOR INTERIORS</p>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </Col></Row></Container>
      </div>
    );
  }
}

export default WhyChooseFull;
