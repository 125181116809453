import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
// import Slider1 from "../../../assets/images/interior-detail/imageA.jpg";
// import Slider2 from "../../../assets/images/interior-detail/imageB.jpg";
// import Slider3 from "../../../assets/images/interior-detail/imageC.jpg";
// import Watch from "../../../assets/images/GetButton.jpg";
// import WatchHover from "../../../assets/images/GetButtonHover.jpg";

//import { watch, watchHover } from "../../../utils/data";
import watch from "../../../assets/images/contactBtn.svg";
import watchHover from "../../../assets/images/contactBtn.svg";


export default class CenterMode extends Component {

  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 800,
      pauseOnHover: false,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
      ],
    };

    return (
      <div>
        <Slider {...settings}>
          {this.props.images?.map((img) => {
            return <div className="feat-img" key={`slider-${img.id}`}>
              <img src={img.picture} alt="" />
              <div className="d-md-flex flex-row align-items-center mt-4">
                <div className="flex-grow-1">
                  <h2 className="headingXl interior">{img.heading}</h2>
                </div>
                <div className="ps-md-3 mt-4 mt-md-0">

                  <a href="/contact" className="CtaBtn">


                    <img src={watch} alt="" className="Btn" />
                    <img src={watchHover} alt="" className="BtnHover" />
                  </a>
                </div>
              </div>
            </div>
          })}
          {/* <div className="feat-img">
            <img src={Slider1} alt="" />

            <div className="d-md-flex flex-row align-items-center mt-4">
              <div className="flex-grow-1">
                <h2 className="headingXl">  globally acclaimed interior &
                  architectural firm </h2>
              </div>
              <div className="ps-md-3 mt-4 mt-md-0">
                <a href="javascript:void(0);" className="CtaBtn">
                  <img src={Watch} alt="" className="Btn" />
                  <img src={WatchHover} alt="" className="BtnHover" />
                </a>
              </div>
            </div>
          </div>
           <div className="feat-img">
            <img src={Slider2} alt="" />
            <div className="d-md-flex flex-row align-items-center mt-4">
              <div className="flex-grow-1">
                <h2 className="headingXl">  globally acclaimed interior &
                  architectural firm </h2>
              </div>
              <div className="ps-md-3 mt-4 mt-md-0">
                <a href="javascript:void(0);" className="CtaBtn">
                  <img src={Watch} alt="" className="Btn" />
                  <img src={WatchHover} alt="" className="BtnHover" />
                </a>
              </div>
            </div>
          </div>
          <div className="feat-img">
            <img src={Slider3} alt="" />
            <div className="d-md-flex flex-row align-items-center mt-4">
              <div className="flex-grow-1">
                <h2 className="headingXl">  globally acclaimed interior &
                  architectural firm </h2>
              </div>
              <div className="ps-md-3 mt-4 mt-md-0">
                <a href="javascript:void(0);" className="CtaBtn">
                  <img src={Watch} alt="" className="Btn" />
                  <img src={WatchHover} alt="" className="BtnHover" />
                </a>
              </div>
            </div>
          </div> */}
        </Slider>
      </div>
    );
  }
}
