import { makeStyles } from '@material-ui/core/styles';
//import Instagram from "../../assets/images/contactMob.png";

export const styles = makeStyles((theme) => ({
  main: {
    overflow: 'hidden',
  },

  mainMiddle: {
    marginTop: '4.5rem',
    marginBottom: '4rem',
    [theme.breakpoints.down("xs")]: {
      marginTop: '2rem',
      marginBottom: '2rem',
    },
  },

  h1: {
    fontSize: '40px',
    color: '#151212',
    fontFamily: 'Cormorant Infant',
    lineHeight: '0.8',
    fontWeight: '500',
    textAlign: 'center',
    textTransform: 'uppercase',
    [theme.breakpoints.down("md")]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "35px",
      textAlign: 'center',
    },
  },



  p: {
    fontFamily: 'Cormorant Infant',
    lineHeight: '1.2',
    textAlign: 'center',
    [theme.breakpoints.down("xs")]: {
      textAlign: 'center',
      color: '#ffffff',
      fontSize: '13px',
    },
  },
  Link: {
    textDecoration: 'underline',
  },

  mY: {
    marginTop: '3rem',
    marginBottom: '3rem',
    [theme.breakpoints.down("xs")]: {
      marginTop: '5rem',
      marginBottom: '5rem',
    }
  },




  AboutOne: {
    borderRadius: '50%',
    [theme.breakpoints.down("xs")]: {

    },
  },
}));
